import { Fragment } from 'react'
import { TextField, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'

import { QuestionDpoType, QuestionType } from './question.types'

const Question = ({ question, control, errors, disabled }: QuestionType) => {
  const questionDpo = `questionDpo${question?.order}` as QuestionDpoType

  return (
    <Fragment key={question?.id}>
      <Typography>
        {question?.order} - {question?.text}
      </Typography>
      <Controller
        name={questionDpo}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            multiline
            minRows={3}
            error={!!errors?.[questionDpo]}
            helperText={errors?.[questionDpo]?.message}
            disabled={disabled}
          />
        )}
      />
    </Fragment>
  )
}

export default Question
