import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { groupBy, orderBy, isEmpty } from 'lodash'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { Box, Button } from '@mui/material'

import {
  ListPageStructure,
  LoadingFeedback,
  PageHead,
  Permitted,
} from 'components'

import {
  GeneralInformations,
  LiaAnswersAnalysisDisapproved,
  LiaAnswersAnalysisPending,
} from './components'

import { LiaAnswerType } from 'types/liaAnswers.types'

import { useFetch, useSnackbar } from 'hooks'

import service from 'service'
import constants from 'constants/index'
import schema, { AnswersLiaReviewFormType } from './schemaAnswersAnalysis'
import routes from 'constants/routes'
import { legitimateInterest } from 'helpers/index'

const LegitimateInterestReviewClient = () => {
  const [loadingAnswerReview, setLoadingAnswerReview] = useState(false)
  const { snackbar } = useSnackbar()

  const { dataProcessId } = useParams()

  const navigate = useNavigate()

  const { response, loading } = useFetch(
    service.dponet.dataProcesses.showWithLia,
    {
      dataProcessId,
    }
  )

  const { PENDING, DISAPPROVED, REVIEW_DISAPPROVED } =
    constants.legitimateInterest.STATUSES_LIA_ANSWER

  const dataProcess = response?.data?.dataProcess

  const liaAnswersPending = orderBy(
    dataProcess?.liaAnswers?.filter((liaAnswer: LiaAnswerType) => {
      return liaAnswer?.status === PENDING
    }),
    'liaQuestion.order',
    'asc'
  )

  const liaAnswersReviewDisapproved = orderBy(
    dataProcess?.liaAnswers?.filter((liaAnswer: LiaAnswerType) => {
      return liaAnswer?.status === REVIEW_DISAPPROVED
    }),
    'liaQuestion.order',
    'asc'
  )

  const liaAnswersDisapproved = orderBy(
    dataProcess?.liaAnswers?.filter((liaAnswer: LiaAnswerType) => {
      return liaAnswer?.status === DISAPPROVED
    }),
    'liaQuestion.order',
    'asc'
  )

  const allLiaAnswersToReviewDisapproveds = [
    ...liaAnswersReviewDisapproved,
    ...liaAnswersDisapproved,
  ]

  const liaAnswersGroupByOrderQuestion = Object.values(
    groupBy(allLiaAnswersToReviewDisapproveds, 'liaQuestion.order')
  )

  const methods = useForm<AnswersLiaReviewFormType>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const onSubmit = async (data: AnswersLiaReviewFormType) => {
    setLoadingAnswerReview(true)
    try {
      await service.dponet.dataProcesses.analysisAnswer({
        dataProcess: {
          analysisAnswers: legitimateInterest.mountAnalysisLiaAnswersData(data),
        },
        dataProcessId,
      })
      handleBackPage()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: 'Erro ao revisar respostas LIA',
        variant: 'error',
      })
    }
    setLoadingAnswerReview(false)
  }

  const handleBackPage = () => {
    navigate(routes.app.legitimateInterest.all)
  }

  return (
    <ListPageStructure>
      <LoadingFeedback open={loading} />
      <PageHead name="Legítimo Interesse (LIA)" />
      <GeneralInformations dataProcess={dataProcess} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {!isEmpty(liaAnswersReviewDisapproved)
            ? liaAnswersGroupByOrderQuestion?.map(
                (liaAnswers: LiaAnswerType[], index) => (
                  <LiaAnswersAnalysisDisapproved
                    key={index}
                    liaAnswers={liaAnswers}
                  />
                )
              )
            : liaAnswersPending?.map((liaAnswer: LiaAnswerType) => (
                <LiaAnswersAnalysisPending
                  key={liaAnswer?.id}
                  liaAnswer={liaAnswer}
                />
              ))}
          <Permitted tag={constants.permissionTags.LEGITIMATE_INTEREST.MANAGE}>
            <Box display="flex" justifyContent="flex-end" gap={2}>
              <Button variant="outlined" onClick={handleBackPage}>
                Voltar
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={loadingAnswerReview}
              >
                Enviar
              </Button>
            </Box>
          </Permitted>
        </form>
      </FormProvider>
    </ListPageStructure>
  )
}

export default LegitimateInterestReviewClient
