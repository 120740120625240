import React, { Children, useState } from 'react'
import { isArray, isObject, isNumber, isString, isNil } from 'lodash'
import { Button, Drawer, Typography } from '@mui/material'

import { BoxBase, ChildrenInput } from './components'
import { useForm } from 'react-hook-form'

import {
  AutoCompleteOptions,
  FiltersComponentType,
  FiltersTypes,
  RangeSliderOptions,
} from 'types/filters.types'
import { formatters } from 'helpers'

const Filters = ({ children, filter }: FiltersComponentType) => {
  const { drawerOpen, setDrawerOpen } = filter
  const { handleSubmit } = useForm()

  const [values, setValues] = useState<FiltersTypes>(() => {
    const filters = filter.filters as FiltersTypes
    delete filters['page']
    return filters
  })

  const handleClose = () => setDrawerOpen(false)
  const handleChange = (
    name: string,
    value:
      | string
      | AutoCompleteOptions
      | AutoCompleteOptions[]
      | RangeSliderOptions
  ) => {
    const newValue = !isArray(value)
      ? isObject(value)
        ? value.id
        : value
      : value?.map((val) => {
          return isNumber(val) ? val : val?.id
        })

    setValues({
      ...values,
      [name]: newValue,
    })
  }

  const clearFilters = () => {
    setValues({})
    filter.setFilters({})
    filter.setDrawerOpen(false)
  }

  const onSubmit = () => {
    let filterValues = values
    if (!isNil(filter.filters)) {
      filterValues = { ...filter.filters, ...values } as FiltersTypes
    }
    filter.setFilters(filterValues)
    filter.setDrawerOpen(false)
  }

  const handleDateChange = (
    name: string,
    newValue: string | AutoCompleteOptions[]
  ) => {
    if (isString(newValue)) {
      const formattedDate = formatters.date.ptBrFormatDate(newValue)

      setValues({
        ...values,
        [name]: formattedDate,
      })
    }
  }

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BoxBase>
          <Typography variant="h5" gutterBottom>
            Filtros
          </Typography>
          {Children.toArray(children).map((child) => {
            const childToClone = child as React.ReactElement
            const value =
              values[childToClone.props.name] || childToClone.props.defaultValue
            return (
              <ChildrenInput
                key={childToClone.props.name}
                handleChange={
                  childToClone.props.fieldConfigs.datePicker
                    ? handleDateChange
                    : handleChange
                }
                child={childToClone}
                initialValue={value}
              />
            )
          })}
          <Button
            onClick={clearFilters}
            variant="outlined"
            color="secondary"
            fullWidth
          >
            Limpar filtros
          </Button>
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Filtrar
          </Button>
        </BoxBase>
      </form>
    </Drawer>
  )
}

export default Filters
