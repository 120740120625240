import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { ListPageStructure, LoadingFeedback, PageHead } from 'components'
import { QuestionForm } from './components'

const CreateQuestion = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { questionnaireId } = useParams()

  const createQuestionFormName = 'create-quiz-question-form'

  return (
    <ListPageStructure>
      <LoadingFeedback open={isLoading} />
      <PageHead name="Criar pergunta" />

      <QuestionForm
        questionnaireId={questionnaireId}
        setIsLoading={setIsLoading}
        formName={createQuestionFormName}
      />
    </ListPageStructure>
  )
}

export default CreateQuestion
