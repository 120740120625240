import { useMemo, useRef } from 'react'
import JoditEditor from 'jodit-react'
import { Box, FormHelperText } from '@mui/material'

import { TextEditorType } from './textEditor.types'

import constants from 'constants/index'
import theme from 'theme'

const TextEditor = ({
  onChange,
  value = '',
  error = false,
  helperText,
  disabled = false,
  configRest,
  ...rest
}: TextEditorType) => {
  const editor = useRef(null)

  const config = useMemo(
    () => ({
      language: 'pt_br',
      spellcheck: true,
      showXPathInStatusbar: false,
      buttons: constants.jodit.DEFAULT_BUTTONS,
      askBeforePasteHTML: false,
      style: {
        fontFamily: 'Roboto',
        fontSize: 16,
        ...(!!error && {
          border: `1px solid ${theme.palette.error.main}`,
          color: theme.palette.error.main,
        }),
      },
      height: 300,
      zIndex: 1,
      ...(disabled && { readonly: true }),
      ...configRest,
    }),
    [error, configRest, disabled]
  )

  return (
    <Box>
      <JoditEditor
        config={config}
        ref={editor}
        onBlur={(value) => onChange(value)}
        value={value}
        {...rest}
      />
      {!!error && (
        <FormHelperText error={!!error}>{String(helperText)}</FormHelperText>
      )}
    </Box>
  )
}

export default TextEditor
