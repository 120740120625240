import { useEffect, useState } from 'react'

import { FilterInput, Filters } from 'components'

import { FilterHookType } from 'types/filters.types'
import constants from 'constants/index'

const IncidentsFilters = ({ filter }: { filter: FilterHookType }) => {
  const [shouldFetch, setShouldFetch] = useState<boolean>(false)

  const { STEPS_OPTIONS } = constants.incidentManegement
  const { STATUSES_OPTIONS } = constants.nonComplianceReport

  useEffect(() => {
    if (filter.drawerOpen && !shouldFetch) setShouldFetch(true)
  }, [filter.drawerOpen])

  const yesOrNoOptions = [
    { id: 'yes', name: 'Sim' },
    { id: 'no', name: 'Não' },
  ]

  return (
    <Filters filter={filter}>
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Identificador', name: 'id' }}
      />
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Empresa', name: 'companyName' }}
      />
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Documento da Empresa', name: 'companyDocument' }}
      />
      <FilterInput
        fieldConfigs={{ datePicker: true }}
        {...{ label: 'Data de criação', name: 'createdAt' }}
      />
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        {...{
          label: 'Etapa',
          name: 'steps',
          options: STEPS_OPTIONS,
          multiple: true,
        }}
      />
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        {...{
          label: 'Tratamento',
          name: 'nonComplianceStatusesIds',
          options: STATUSES_OPTIONS,
          multiple: true,
        }}
      />
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        {...{
          label: 'Parecer',
          name: 'legalOpinion',
          options: yesOrNoOptions,
          multiple: false,
        }}
      />
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        {...{
          label: 'Notificação',
          name: 'shouldNotify',
          options: yesOrNoOptions,
          multiple: false,
        }}
      />
    </Filters>
  )
}

export default IncidentsFilters
