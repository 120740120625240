import { colors } from '@mui/material'

const FINALIZED_STATUS = 'finalized'
const ON_TIME_STATUS = 'onTime'
const OVERDUE_STATUS = 'overdue'
const PENDING_STATUS = 'pending'
const RISK_ACCEPTED_STATUS = 'riskAccepted'
const INACTIVE_STATUS = 'inactive'
const FROZEN_STATUS = 'frozen'
const SUBSTITUTED_STATUS = 'substituído'
const CLOSED_STATUS = 'closed'
const UNDEFINED_STATUS = 'undefined'

const ALL_STATUSES = [
  PENDING_STATUS,
  ON_TIME_STATUS,
  OVERDUE_STATUS,
  RISK_ACCEPTED_STATUS,
  FINALIZED_STATUS,
] as const

const STATUSES_COLORS = {
  [PENDING_STATUS]: colors.yellow['100'],
  [ON_TIME_STATUS]: colors.blue['100'],
  [OVERDUE_STATUS]: colors.red['A100'],
  [RISK_ACCEPTED_STATUS]: colors.lightGreen['A100'],
  [FINALIZED_STATUS]: colors.green['A700'],
  [INACTIVE_STATUS]: colors.grey['A400'],
  [FROZEN_STATUS]: colors.lightBlue['200'],
  [SUBSTITUTED_STATUS]: colors.orange['200'],
  [CLOSED_STATUS]: colors.red['200'],
}

const STATUSES_TEXT_COLORS = {
  [PENDING_STATUS]: colors.yellow['900'],
  [ON_TIME_STATUS]: colors.blue['700'],
  [OVERDUE_STATUS]: colors.red['900'],
  [RISK_ACCEPTED_STATUS]: colors.lightGreen['900'],
  [FINALIZED_STATUS]: colors.grey['100'],
  [INACTIVE_STATUS]: colors.grey['800'],
  [FROZEN_STATUS]: colors.lightBlue['600'],
  [SUBSTITUTED_STATUS]: colors.orange['900'],
  [CLOSED_STATUS]: colors.red['A400'],
}

const STATUSES_LABELS = {
  [PENDING_STATUS]: 'Pendente',
  [ON_TIME_STATUS]: 'No prazo',
  [OVERDUE_STATUS]: 'Vencido',
  [RISK_ACCEPTED_STATUS]: 'Risco assumido',
  [FINALIZED_STATUS]: 'Finalizado',
  [INACTIVE_STATUS]: 'Inativo',
  [FROZEN_STATUS]: 'Congelado',
  [SUBSTITUTED_STATUS]: 'Substituído',
  [CLOSED_STATUS]: 'Fechado',
  [UNDEFINED_STATUS]: '-',
}

const STATUSES_INFO = {
  [PENDING_STATUS]: {
    color: STATUSES_TEXT_COLORS[PENDING_STATUS],
    background: STATUSES_COLORS[PENDING_STATUS],
    label: STATUSES_LABELS[PENDING_STATUS],
  },
  [ON_TIME_STATUS]: {
    color: STATUSES_TEXT_COLORS[ON_TIME_STATUS],
    background: STATUSES_COLORS[ON_TIME_STATUS],
    label: STATUSES_LABELS[ON_TIME_STATUS],
  },
  [OVERDUE_STATUS]: {
    color: STATUSES_TEXT_COLORS[OVERDUE_STATUS],
    background: STATUSES_COLORS[OVERDUE_STATUS],
    label: STATUSES_LABELS[OVERDUE_STATUS],
  },
  [RISK_ACCEPTED_STATUS]: {
    color: STATUSES_TEXT_COLORS[RISK_ACCEPTED_STATUS],
    background: STATUSES_COLORS[RISK_ACCEPTED_STATUS],
    label: STATUSES_LABELS[RISK_ACCEPTED_STATUS],
  },
  [FINALIZED_STATUS]: {
    color: STATUSES_TEXT_COLORS[FINALIZED_STATUS],
    background: STATUSES_COLORS[FINALIZED_STATUS],
    label: STATUSES_LABELS[FINALIZED_STATUS],
  },
  [INACTIVE_STATUS]: {
    color: STATUSES_TEXT_COLORS[INACTIVE_STATUS],
    background: STATUSES_COLORS[INACTIVE_STATUS],
    label: STATUSES_LABELS[INACTIVE_STATUS],
  },
  [FROZEN_STATUS]: {
    color: STATUSES_TEXT_COLORS[FROZEN_STATUS],
    background: STATUSES_COLORS[FROZEN_STATUS],
    label: STATUSES_LABELS[FROZEN_STATUS],
  },

  [SUBSTITUTED_STATUS]: {
    color: STATUSES_TEXT_COLORS[SUBSTITUTED_STATUS],
    background: STATUSES_COLORS[SUBSTITUTED_STATUS],
    label: STATUSES_LABELS[SUBSTITUTED_STATUS],
  },
  [CLOSED_STATUS]: {
    color: STATUSES_TEXT_COLORS[CLOSED_STATUS],
    background: STATUSES_COLORS[CLOSED_STATUS],
    label: STATUSES_LABELS[CLOSED_STATUS],
  },
  [UNDEFINED_STATUS]: {
    label: STATUSES_LABELS[UNDEFINED_STATUS],
  },
} as const

const ON_TIME_STATUS_ID = 1
const OVERDUE_STATUS_ID = 2
const FINALIZED_STATUS_ID = 3
const PENDING_STATUS_ID = 4
const CLOSED_STATUS_ID = 5
const RISK_ACCEPTED_STATUS_ID = 6
const SUBSTITUTED_STATUS_ID = 7
const FROZEN_STATUS_ID = 8
const INACTIVE_STATUS_ID = 9

const STATUSES_ID = {
  [ON_TIME_STATUS_ID]: STATUSES_INFO[ON_TIME_STATUS],
  [OVERDUE_STATUS_ID]: STATUSES_INFO[OVERDUE_STATUS],
  [FINALIZED_STATUS_ID]: STATUSES_INFO[FINALIZED_STATUS],
  [PENDING_STATUS_ID]: STATUSES_INFO[PENDING_STATUS],
  [RISK_ACCEPTED_STATUS_ID]: STATUSES_INFO[RISK_ACCEPTED_STATUS],
  [INACTIVE_STATUS_ID]: STATUSES_INFO[INACTIVE_STATUS],
  [FROZEN_STATUS_ID]: STATUSES_INFO[FROZEN_STATUS],
  [CLOSED_STATUS_ID]: STATUSES_INFO[CLOSED_STATUS],
  [SUBSTITUTED_STATUS_ID]: STATUSES_INFO[SUBSTITUTED_STATUS],
  undefined: STATUSES_INFO[UNDEFINED_STATUS],
}
export { STATUSES_LABELS }

const STATUSES_OPTIONS = [
  { id: ON_TIME_STATUS_ID, name: 'No prazo' },
  { id: OVERDUE_STATUS_ID, name: 'Vencido' },
  { id: FINALIZED_STATUS_ID, name: 'Finalizado' },
  { id: PENDING_STATUS_ID, name: 'Pendente' },
  { id: CLOSED_STATUS_ID, name: 'Fechado' },
  { id: RISK_ACCEPTED_STATUS_ID, name: 'Risco Assumido' },
  { id: SUBSTITUTED_STATUS_ID, name: 'Substituído' },
  { id: FROZEN_STATUS_ID, name: 'Congelado' },
  { id: INACTIVE_STATUS_ID, name: 'Inativo' },
]

export default {
  ALL_STATUSES,
  FINALIZED_STATUS,
  ON_TIME_STATUS,
  OVERDUE_STATUS,
  PENDING_STATUS,
  RISK_ACCEPTED_STATUS,
  STATUSES_COLORS,
  STATUSES_ID,
  STATUSES_INFO,
  STATUSES_LABELS,
  STATUSES_OPTIONS,
  STATUSES_TEXT_COLORS,
}
