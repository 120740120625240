import { useState } from 'react'

import {
  Autocomplete,
  Box,
  CircularProgress,
  InputLabel,
  Slider,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import {
  AutocompletConfigs,
  FilterInputProps,
  RestType,
} from './filterInput.types'

const FilterInput = ({
  fieldConfigs,
  loading,
  marks,
  inputLabel,
  autocompleteOnChange,
  ...rest
}: FilterInputProps) => {
  const [currentTimer, setTimer] = useState<NodeJS.Timeout>()
  const [isLoading, setIsLoading] = useState(loading)
  const [datePickerValue, setDatePickerValue] = useState<Date | null>()

  if (fieldConfigs.textFieldInput) {
    return <TextField {...rest} fullWidth />
  }

  if (fieldConfigs.datePicker) {
    const restProps = rest as RestType
    return (
      <DatePicker
        {...rest}
        value={datePickerValue}
        onChange={(value: Date | null) => {
          restProps.onChange({
            target: { value: value?.toDateString() },
          })
          setDatePickerValue(value)
        }}
      />
    )
  }

  if (fieldConfigs.fieldAutocomplete) {
    const configs = rest as AutocompletConfigs
    return (
      <Autocomplete
        noOptionsText="Sem opções"
        {...rest}
        options={configs?.options || []}
        loadingText="Carregando..."
        renderInput={(params) => (
          <TextField
            {...params}
            {...rest}
            {...(!!autocompleteOnChange && {
              onChange: (event) => {
                const value = event.target.value
                if (currentTimer) clearTimeout(currentTimer)
                if (value.length < 3) return
                setIsLoading(true)
                const timer = setTimeout(() => {
                  autocompleteOnChange(event.target.value)
                  setIsLoading(false)
                }, 1200)
                setTimer(timer)
                return event
              },
            })}
            {...(!autocompleteOnChange &&
              loading && {
                InputProps: {
                  endAdornment: (
                    <Box
                      display="flex"
                      alignItems="center"
                      ml="100%"
                      width={20}
                      height={20}
                      justifyContent="center"
                    >
                      <CircularProgress variant="indeterminate" size={15} />
                    </Box>
                  ),
                },
              })}
            InputLabelProps={{
              children: null,
            }}
          />
        )}
        loading={!!autocompleteOnChange && isLoading}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => option?.name || ''}
      />
    )
  }

  if (fieldConfigs.rangeSlider) {
    return (
      <Box mx={2} width="95%">
        <InputLabel>{inputLabel}</InputLabel>
        <Slider {...rest} marks={marks} />
      </Box>
    )
  }

  return <></>
}

export default FilterInput
