import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { X as XIcon } from 'react-feather'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'

import { BasicDialog, LoadingFeedback } from 'components'

import { AddFieldDialogType } from './addFieldDialog.types'
import { DataProcessTemplateQuestionOptionType } from 'types/dataProcessTemplate.types'

import {
  useDataProcessQuestionTemplate,
  useSnackbar,
  usePermission,
} from 'hooks'

import { formatters } from 'helpers'
import schema, { AddFieldDialogSchemaType } from './schema'
import service from 'service/index'
import permissionTags from 'constants/permissionTags'

const AddFieldDialog = ({
  onClose,
  refresh,
  open,
  step,
  dataProcessTemplateQuestion,
  edit,
}: AddFieldDialogType) => {
  const [options, setOptions] = useState<string[]>([])
  const [optionsEdit, setOptionsEdit] = useState<
    DataProcessTemplateQuestionOptionType[] | undefined
  >([])

  const [loading, setLoading] = useState(false)

  const { snackbar } = useSnackbar()
  const { dataProcessTemplate } = useDataProcessQuestionTemplate()
  const { isPermitted } = usePermission()

  const isPermittedToManage = isPermitted(
    permissionTags.DATA_PROCESS_TEMPLATE.MANAGE
  )

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      requiredField: dataProcessTemplateQuestion?.requiredField || false,
      title: dataProcessTemplateQuestion?.title || '',
      description: dataProcessTemplateQuestion?.description || '',
      fieldType: dataProcessTemplateQuestion?.fieldType || '',
      option: '',
    },
  })

  const editQuestionWithOption = () => {
    if (!edit) return

    const optionsArray =
      dataProcessTemplateQuestion?.dataProcessTemplateQuestionOptions?.map(
        (question) => {
          return question.option
        }
      ) || []

    setOptions(optionsArray)
    setOptionsEdit(
      dataProcessTemplateQuestion?.dataProcessTemplateQuestionOptions
    )
    setValue('option', '')
  }

  useEffect(() => {
    if (!open) {
      reset()
    }
    editQuestionWithOption()
  }, [dataProcessTemplateQuestion, open])

  const onSubmit = async (data: AddFieldDialogSchemaType) => {
    try {
      setLoading(true)

      if (edit) {
        await service.dponet.dataProcessTemplateQuestion.update({
          dataProcessTemplateId: dataProcessTemplate?.id,
          dataProcessTemplateQuestionId: dataProcessTemplateQuestion?.id,
          data: {
            dataProcessTemplateQuestion: {
              ...data,
              options: optionsEdit,
            },
          },
        })
      } else {
        await service.dponet.dataProcessTemplateQuestion.create({
          dataProcessTemplateId: dataProcessTemplate?.id,
          data: {
            dataProcessTemplateQuestion: {
              ...data,
              step,
              options: options.map((option) => ({ option: option })),
            },
          },
        })
      }
      reset()
      onClose()
      refresh()
      setOptions([])
    } catch (error) {
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleAddOption = () => {
    const option = watch('option')

    if (!option) return

    setOptions((options) => [...options, option])
    setValue('option', '')
    if (edit) {
      const newOptionEdit = { option: option }
      setOptionsEdit((optionsEdit) => [...(optionsEdit || []), newOptionEdit])
    }
  }

  const handleRemoveOption = (index: number) => {
    setOptions((options) => options.filter((_, i) => i !== index))
    if (edit) {
      setOptionsEdit(
        (optionsEdit) => optionsEdit?.filter((_, i) => i !== index)
      )
    }
  }

  return (
    <BasicDialog
      title={edit ? 'Editar Campo' : 'Novo Campo'}
      open={open}
      onClose={onClose}
      form="add-field-data-process-form"
      disabled={!isPermittedToManage}
    >
      <LoadingFeedback open={loading} />
      <form id="add-field-data-process-form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <FormControl
                  component={Box}
                  width="fit-content"
                  disabled={!isPermittedToManage}
                >
                  <FormControlLabel
                    control={<Switch {...field} checked={!!field.value} />}
                    label="Campo Obrigatório"
                  />
                </FormControl>
              )}
              control={control}
              name="requiredField"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.title}
                  helperText={errors?.title?.message}
                  label="Título"
                  type="text"
                  fullWidth
                  disabled={!isPermittedToManage}
                />
              )}
              control={control}
              name="title"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors?.title}
                  helperText={errors?.title?.message}
                  label="Explicação do campo"
                  type="text"
                  fullWidth
                  disabled={!isPermittedToManage}
                />
              )}
              control={control}
              name="description"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth error={!!errors.fieldType?.message}>
                  <InputLabel id="field-type-label">
                    Defina o tipo do campo
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="field-type-label"
                    label="Defina o tipo do campo"
                    disabled={edit}
                  >
                    <MenuItem value="single_select">
                      Seleção (várias opções, apenas uma escolha)
                    </MenuItem>
                    <MenuItem value="multi_select">
                      Multipla Escolha (várias opções, várias escolhas)
                    </MenuItem>
                    <MenuItem value="text">Campo de Texto</MenuItem>
                  </Select>
                  {!!errors.fieldType && (
                    <FormHelperText>{errors.fieldType?.message}</FormHelperText>
                  )}
                </FormControl>
              )}
              control={control}
              name="fieldType"
            />
          </Grid>
          {['single_select', 'multi_select'].includes(watch('fieldType')) && (
            <>
              {!isEmpty(options) && (
                <Grid item xs={12}>
                  <Box display="flex" gap={1} flexDirection="column">
                    {options.map((option, index) => (
                      <Paper key={index} elevation={0} variant="outlined">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          p={2}
                        >
                          <Typography>{option}</Typography>
                          <IconButton onClick={() => handleRemoveOption(index)}>
                            <XIcon size={20} />
                          </IconButton>
                        </Box>
                      </Paper>
                    ))}
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box display="flex" gap={4}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors?.option}
                        helperText={errors?.option?.message}
                        label="Opção"
                        type="text"
                        fullWidth
                      />
                    )}
                    control={control}
                    name="option"
                  />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button variant="outlined" onClick={handleAddOption}>
                      Adicionar
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </BasicDialog>
  )
}

export default AddFieldDialog
