import companies from './companies'
import companyTrails from './companyTrails'
import dataCollected from './dataCollected'
import dataProcess from './dataProcess'
import dataTreatmentAgents from './dataTreatmentAgents'
import faqs from './faqs'
import functionalities from './functionalities'
import incidentManegement from './incidentManegement'
import incidents from './incidents'
import jodit from './jodit'
import jsPdf from './jsPdf'
import legalFramework from './legalFramework'
import legitimateInterest from './legitimateInterest'
import menuItems from './menuItems'
import nonComplianceReport from './nonComplianceReport'
import observers from './observers'
import organizationCompanies from './organizationCompanies'
import organizations from './organizations'
import organizationUsers from './organizationUsers'
import outsourcedDpos from './outsourcedDpos'
import permissionTags from './permissionTags'
import privacyPortal from './privacyPortal'
import privacyPortalForm from './privacyPortalForm'
import profiles from './profiles'
import questionnaire from './questionnaire'
import routes from './routes'
import tasks from './tasks'
import ticketFeedbacks from './ticketFeedbacks'
import tickets from './tickets'
import validations from './validations'

const constants = {
  companies,
  companyTrails,
  dataCollected,
  dataProcess,
  dataTreatmentAgents,
  faqs,
  functionalities,
  incidentManegement,
  incidents,
  jodit,
  jsPdf,
  legalFramework,
  legitimateInterest,
  menuItems,
  nonComplianceReport,
  observers,
  organizationCompanies,
  organizations,
  organizationUsers,
  outsourcedDpos,
  permissionTags,
  privacyPortal,
  privacyPortalForm,
  profiles,
  questionnaire,
  routes,
  tasks,
  ticketFeedbacks,
  tickets,
  validations,
}

export default constants
