import { Box, Divider, Grid } from '@mui/material'
import { useParams } from 'react-router-dom'

import {
  DefaultPageStructure,
  LoadingFeedback,
  MUI,
  PageHead,
  PagePaper,
} from 'components'
import {
  TicketFeedback,
  TicketForm,
  TicketMessages,
  ReopenTicket,
} from './components'

import { TicketType } from 'types/ticket.types'

import { useFetch } from 'hooks'

import service from 'service'
import { validates } from 'helpers'
import ticketConstants from 'constants/tickets'

const MyTicketContent = () => {
  const { ticketId } = useParams()

  const { response, loading, refresh } = useFetch(
    service.dponet.ticket.showMyTicket,
    {
      ticketId,
    },
    []
  )

  const {
    response: responseMessages,
    loading: loadingMessages,
    refresh: refreshMessages,
  } = useFetch(service.dponet.ticket.getMyTicketMessages, {
    ticketId,
  })

  const ticket: TicketType = response?.data?.ticket
  const ticketMessages = responseMessages?.data?.ticketMessages || []

  const shouldPermitReopen =
    ticket?.status?.id === ticketConstants.FINISHED_TICKET_ID &&
    ticket?.permissionReopen

  return (
    <DefaultPageStructure>
      <PageHead name={`Ticket ID ${ticketId}`} />
      <LoadingFeedback open={loading || loadingMessages} />

      <PagePaper>
        <Box p={3}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box display="flex" gap={1}>
                <MUI.Chip
                  label={ticketId}
                  variant="filled"
                  size="small"
                  $fontWeight={500}
                />
                <MUI.Chip
                  label={ticket?.status?.name.toUpperCase()}
                  variant="filled"
                  size="small"
                  $background={validates.tickets.statusBackgroundColor(
                    ticket?.status?.id
                  )}
                  $color="white"
                  $fontWeight={500}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <MUI.Typography fontWeight={700} variant="body1" gutterBottom>
                Título: {ticket?.title}
              </MUI.Typography>
              <MUI.Typography variant="body2">
                Categoria:&nbsp;
                {validates.tickets.labelTranslation(ticket?.ticketType)}
              </MUI.Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {<TicketMessages ticketMessages={ticketMessages} />}
            </Grid>
            {ticket &&
              ticket?.status.id !== ticketConstants.FINISHED_TICKET_ID && (
                <Grid item xs={12}>
                  <Divider />
                  <TicketForm ticketId={ticketId} refresh={refreshMessages} />
                </Grid>
              )}
          </Grid>
          {shouldPermitReopen && (
            <ReopenTicket ticketId={ticketId} refresh={refresh} />
          )}
        </Box>
      </PagePaper>

      {ticket?.status.id === ticketConstants.FINISHED_TICKET_ID && (
        <TicketFeedback
          refresh={refresh}
          ticketId={ticketId}
          ticketFeedback={ticket?.ticketFeedback}
        />
      )}
    </DefaultPageStructure>
  )
}

export default MyTicketContent
