const INCIDENT_VIOLATION_TYPES = [
  {
    value: 1,
    name: 'Confidencialidade',
    description: 'Houve acesso não autorizado aos dados, violando seu sigilo.',
  },
  {
    value: 2,
    name: 'Integridade',
    description:
      'Houve alteração ou destruição de dados de maneira não autorizada ou acidental.',
  },
  {
    value: 3,
    name: 'Disponibilidade',
    description:
      'Houve perda ou dificuldade de acesso aos dados por período significativo.',
  },
]

const COMMUNICATION_HOLDER_OPTIONS = [
  {
    id: 'yes',
    name: 'Sim.',
  },
  {
    id: 'not_risk',
    name: 'Não, por não haver risco ou dano relevante a eles',
  },
  {
    id: 'comunication_progress',
    name: 'Não, mas o processo de comunicação está em andamento.',
  },
  {
    id: 'risk_investigated',
    name: 'Não, vez que o risco do incidente ainda está sendo apurado. (comunicação preliminar)',
  },
]

const IMPACT_HOLDER = [
  {
    id: 'may_not_take_damage',
    name: 'Podem não sofrer danos negligenciáveis ou superáveis sem dificuldade.',
  },
  {
    id: 'may_suffer_damage',
    name: 'Podem sofrer danos, superáveis com certa dificuldade.',
  },
  {
    id: 'may_suffer_significant_damage',
    name: 'Podem sofrer danos importantes, superáveis com muita dificuldade.',
  },
  {
    id: 'may_suffer_injury',
    name:
      'Podem sofrer lesão ou ofensa a direitos ou interesses difusos, coletivos ou individuais, que dadas as ' +
      'circunstâncias, ocasionam ou tem potencial para ocasionar dano significativo ou irreversível.',
  },
]

const SECURITY_OPTIONS = [
  {
    id: 'yes_complete',
    name: '<strong>Sim</strong>, integralmente protegidos por criptografia / pseudonimização / etc.',
    status:
      'Sim, integralmente protegidos por criptografia / pseudonimização / etc.',
  },
  {
    id: 'yes_partial',
    name: '<strong>Sim</strong>, parcialmente protegidos por criptografia / pseudonimização / etc',
    status:
      'Sim, parcialmente protegidos por criptografia / pseudonimização / etc',
  },
  {
    id: 'no',
    name: '<strong>Não</strong>',
    status: 'Não',
  },
]

const STEPS = [
  {
    id: 0,
    name: 'detection',
  },
  {
    id: 1,
    name: 'investigation',
  },
  {
    id: 2,
    name: 'avaliation',
  },
  {
    id: 3,
    name: 'detaling',
  },
  {
    id: 4,
    name: 'treatment',
  },
  {
    id: 5,
    name: 'communication',
  },
  {
    id: 6,
    name: 'lessons_learned',
  },
]

export default {
  COMMUNICATION_HOLDER_OPTIONS,
  IMPACT_HOLDER,
  INCIDENT_VIOLATION_TYPES,
  SECURITY_OPTIONS,
  STEPS,
}
