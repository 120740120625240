import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { Controller } from 'react-hook-form'

import { QuestionRadioType } from './questionRadio.types'

const QuestionRadio = ({
  name,
  questionText,
  errorMessage,
  control,
  disabled,
}: QuestionRadioType) => (
  <Box mt={4}>
    <FormControl error={!!errorMessage}>
      <FormLabel id={`${name}-label`}>{questionText}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup aria-labelledby={`${name}-label`} row {...field}>
            <FormControlLabel
              value="yes"
              control={<Radio disabled={disabled} />}
              label="SIM"
            />
            <FormControlLabel
              value="no"
              control={<Radio disabled={disabled} />}
              label="NÃO"
            />
          </RadioGroup>
        )}
      />
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  </Box>
)

export default QuestionRadio
