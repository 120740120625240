import * as yup from 'yup'

const schema = yup.object({
  whatQuestion: yup.string().max(1000),
  howQuestion: yup.string().max(1000),
  becauseQuestion: yup.string().max(1000),
  whereQuestion: yup.string().max(1000),
  howPriceQuestion: yup.string().max(25),
  link: yup.string().url('O link deve ser uma URL válida').max(250),
  file: yup.mixed().nullable(),
})

export type QuestionFormSchemaType = yup.InferType<typeof schema>

export type QuestionFormControlType = keyof QuestionFormSchemaType

export default schema
