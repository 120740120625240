import axios from 'axios'

import { getBaseURL } from 'service/env'
import service from 'service'
import api from 'service/api'

const dponetAPI = api.create('dponet')
const baseURL = getBaseURL('dponet')

type TicketRequestType = {
  ticketId?: string | number
  params?: object
  ticket?: object
}

const getAll = async (params?: object) => {
  return dponetAPI.get(`/advisors/tickets/juridic`, {
    params,
  })
}

const get = async ({ ticketId = '', params = {} }: TicketRequestType) => {
  return dponetAPI.get(`/advisors/tickets/${ticketId}/juridic`, {
    params,
  })
}

const update = async ({ ticketId = '', ticket = {} }: TicketRequestType) => {
  return dponetAPI.put(`/advisors/tickets/${ticketId}/juridic`, {
    ticket,
  })
}

const getMessages = async ({
  ticketId = '',
  params = {},
}: TicketRequestType) => {
  return dponetAPI.get(`/advisors/tickets/${ticketId}/juridic/messages`, {
    params,
  })
}

const getLogs = async ({ ticketId = '', params = {} }: TicketRequestType) => {
  return dponetAPI.get(`/advisors/tickets/${ticketId}/juridic/logs`, {
    params,
  })
}

const postMessages = async ({
  ticketId,
  ticketMessages,
}: {
  ticketId?: string | number
  ticketMessages: {
    kind: number
    content?: string
    attachments?: File[]
  }
}) => {
  const data = new FormData()

  data.append('ticket_messages[kind]', String(ticketMessages?.kind))
  data.append('ticket_messages[content]', ticketMessages?.content || '')

  if (ticketMessages?.attachments) {
    for (const value of Object.entries(ticketMessages?.attachments)) {
      data.append(`ticket_messages[attachments][]`, value[1])
    }
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/advisors/tickets/${ticketId}/juridic/messages`,
    data: data,
    ...config,
  })
}

const reopenTicket = async ({ ticketId = '' }: TicketRequestType) => {
  return await dponetAPI.put(`/advisors/tickets/juridic/${ticketId}/reopen`)
}

const closeTicket = async ({ ticketId }: TicketRequestType) => {
  return await dponetAPI.put(`/advisors/tickets/juridic/${ticketId}/close`)
}

export default {
  closeTicket,
  get,
  getAll,
  getLogs,
  getMessages,
  postMessages,
  reopenTicket,
  update,
}
