import { useEffect } from 'react'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom'

import { LoadingFeedback, OrganizationDependentRoute } from 'components'
import {
  Company,
  DataProcessTemplate,
  DataProcessTemplateQuestion,
  Error,
  Faqs,
  FirstAccess,
  IncidentManegements,
  Invite,
  LegalFrameworks,
  LegalTicket,
  LegitimateInterest,
  MyTicket,
  Organization,
  PrivacyPortal,
  Profile,
  Questionnaires,
  Tag,
  Task,
  Ticket,
} from 'views'
import { MainLayout } from 'layouts'

import { useAuth, useOrganization } from 'hooks'

import {
  LegitimateInterestProvider,
  IncidentManegementProvider,
} from 'providers'
import routes from 'constants/routes'
import organizationUsersConstants from 'constants/organizationUsers'
import outsourcedDposConstants from 'constants/outsourcedDpos'
import observersConstants from 'constants/observers'

import Incidents from 'views/Incidents'

const AppRoute = () => {
  const { loaded, user } = useAuth()
  const { organizations, availableOrganizations, loadedOrganizations } =
    useOrganization()

  const navigate = useNavigate()
  const location = useLocation()

  const removeAppPrefix = (route: string) => {
    return route.replace('/app', '')
  }

  const hasOrganizationPendingInvite = () => {
    return organizations?.some(
      (organization) =>
        organization.organizationUser.status ===
        organizationUsersConstants.PENDING_STATUS
    )
  }

  const hasOutsourcedDpoPendingInvite = () => {
    return user?.outsourcedDpos?.some(
      (outsourcedDpo) =>
        outsourcedDpo.status === outsourcedDposConstants.PENDING_STATUS
    )
  }

  const hasObserverPendingInvite = () => {
    return user?.observers?.some(
      (observer) => observer.status === observersConstants.PENDING_STATUS
    )
  }

  const redirect = () => {
    if (hasOutsourcedDpoPendingInvite()) {
      if (location.pathname === routes.app.invites.outsourcedDpo) return

      return navigate(routes.app.invites.outsourcedDpo)
    }

    if (hasObserverPendingInvite()) {
      if (location.pathname === routes.app.invites.observer) return

      return navigate(routes.app.invites.observer)
    }

    if (hasOrganizationPendingInvite()) {
      if (location.pathname === routes.app.invites.organization) return

      return navigate(routes.app.invites.organization)
    }

    if (availableOrganizations?.length === 0) {
      if (location.pathname === routes.app.firstAccess) return

      return navigate(routes.app.firstAccess)
    }

    if (
      location.pathname === routes.app.invites.organization ||
      location.pathname === routes.app.invites.outsourcedDpo ||
      location.pathname === routes.app.invites.observer ||
      location.pathname === routes.app.firstAccess
    )
      return navigate(routes.app.organizations.all)
  }

  useEffect(() => {
    if (loadedOrganizations) redirect()
  }, [loadedOrganizations, location, user])

  return (
    <MainLayout>
      <LoadingFeedback open={!loaded || !loadedOrganizations} />
      <Routes>
        <Route
          path={removeAppPrefix(routes.app.organizations.update)}
          element={<Organization.OrganizationUpdate />}
        />
        <Route
          path={removeAppPrefix(routes.app.organizations.all)}
          element={<Organization.OrganizationMain />}
        />
        <Route
          path={removeAppPrefix(routes.app.profiles.all)}
          element={
            <OrganizationDependentRoute>
              <Profile.ProfileMain />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.profiles.new)}
          element={
            <OrganizationDependentRoute>
              <Profile.ProfileNew />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.profiles.update)}
          element={
            <OrganizationDependentRoute>
              <Profile.ProfileUpdate />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.organizations.dashboard)}
          element={
            <OrganizationDependentRoute>
              <Organization.OrganizationDashboard />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.profiles.all)}
          element={
            <OrganizationDependentRoute>
              <Profile.ProfileMain />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.tasks.all)}
          element={
            <OrganizationDependentRoute>
              <Task.TaskMain />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.companies.all)}
          element={
            <OrganizationDependentRoute>
              <Company.CompanyMain />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.companies.update)}
          element={
            <OrganizationDependentRoute>
              <Company.CompanyUpdate />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.legalFrameworks.all)}
          element={
            <OrganizationDependentRoute>
              <LegalFrameworks.LegalFrameworksMain />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.myTickets.all)}
          element={
            <OrganizationDependentRoute>
              <MyTicket.MyTicketMain />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.myTickets.message)}
          element={
            <OrganizationDependentRoute>
              <MyTicket.MyTicketContent />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.privacyPortal.ticket)}
          element={
            <OrganizationDependentRoute>
              <Ticket.TicketMain />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.privacyPortal.ticketMessage)}
          element={
            <OrganizationDependentRoute>
              <Ticket.TicketContent />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.tickets.all)}
          element={
            <OrganizationDependentRoute>
              <LegalTicket.LegalTicketMain />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.tickets.message)}
          element={
            <OrganizationDependentRoute>
              <LegalTicket.LegalTicketContent />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.tags.all)}
          element={<Tag.TagMain />}
        />
        <Route
          path={removeAppPrefix(routes.app.firstAccess)}
          element={<FirstAccess.FirstAccessMain />}
        />
        <Route
          path={removeAppPrefix(routes.app.invites.organization)}
          element={<Invite.InviteOrganization />}
        />
        <Route
          path={removeAppPrefix(routes.app.invites.outsourcedDpo)}
          element={<Invite.InviteOutsourcedDpo />}
        />
        <Route
          path={removeAppPrefix(routes.app.invites.observer)}
          element={<Invite.InviteObserver />}
        />
        <Route
          path={removeAppPrefix(routes.app.privacyPortal.serviceSettings)}
          element={
            <OrganizationDependentRoute>
              <PrivacyPortal.PrivacyPortalSettings />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.incidents.all)}
          element={
            <OrganizationDependentRoute>
              <Incidents.Main />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.incidentManegements.edit)}
          element={
            <OrganizationDependentRoute>
              <IncidentManegementProvider>
                <IncidentManegements.Edit />
              </IncidentManegementProvider>
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.privacyPortal.editAnswer)}
          element={
            <OrganizationDependentRoute>
              <PrivacyPortal.PrivacyPortalAnswerEdit />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.questionnaires.all)}
          element={
            <OrganizationDependentRoute>
              <Questionnaires.QuestionnairesMain />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.faqs.all)}
          element={
            <OrganizationDependentRoute>
              <Faqs.Main />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.questionnaires.edit)}
          element={
            <OrganizationDependentRoute>
              <Questionnaires.QuestionnairesEdit />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.questionnaires.createQuestion)}
          element={
            <OrganizationDependentRoute>
              <Questionnaires.CreateQuestion />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.questionnaires.updateQuestion)}
          element={
            <OrganizationDependentRoute>
              <Questionnaires.EditQuestion />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.faqs.show)}
          element={
            <OrganizationDependentRoute>
              <Faqs.Show />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.legitimateInterest.all)}
          element={
            <OrganizationDependentRoute>
              <LegitimateInterest.Main />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.legitimateInterest.show)}
          element={
            <OrganizationDependentRoute>
              <LegitimateInterestProvider>
                <LegitimateInterest.Show />
              </LegitimateInterestProvider>
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.legitimateInterest.reviewClient)}
          element={
            <OrganizationDependentRoute>
              <LegitimateInterest.ReviewClient />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.legitimateInterest.reviewDpo)}
          element={
            <OrganizationDependentRoute>
              <LegitimateInterestProvider>
                <LegitimateInterest.ReviewDpo />
              </LegitimateInterestProvider>
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.dataProcesses.templates.all)}
          element={
            <OrganizationDependentRoute>
              <DataProcessTemplate.Main />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.dataProcesses.templates.new)}
          element={
            <OrganizationDependentRoute>
              <DataProcessTemplate.New />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(routes.app.dataProcesses.templates.edit)}
          element={
            <OrganizationDependentRoute>
              <DataProcessTemplate.Edit />
            </OrganizationDependentRoute>
          }
        />
        <Route
          path={removeAppPrefix(
            routes.app.dataProcesses.templates.questions.edit
          )}
          element={
            <OrganizationDependentRoute>
              <DataProcessTemplateQuestion.Edit />
            </OrganizationDependentRoute>
          }
        />
        <Route path="/forbidden" element={<Error.Forbidden />} />
        <Route path="/not-found" element={<Error.NotFound />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Routes>
    </MainLayout>
  )
}

export default AppRoute
