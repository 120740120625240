import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  Button,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { reverse } from 'named-urls'
import { isEmpty } from 'lodash'

import { DocumentAttachment } from './components'

import { useSnackbar } from 'hooks'

import service from 'service'
import { formatters } from 'helpers'
import routes from 'constants/routes'
import schema, { QuestionFormSchemaType } from './schema'
import { ActionPlanFormType } from './ActionPlanForm.types'

const ActionPlanForm = ({
  actionPlan,
  documents,
  questionnaireId,
  quizQuestionId,
  setIsLoading,
  formName,
  refresh,
}: ActionPlanFormType) => {
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()

  const [files, setFile] = useState<File[] | null>([])
  const [opinionFileError, setOpinionFileError] = useState(false)

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<QuestionFormSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      whatQuestion: actionPlan?.whatQuestion || '',
      howQuestion: actionPlan?.howQuestion || '',
      becauseQuestion: actionPlan?.becauseQuestion || '',
      whereQuestion: actionPlan?.whereQuestion || '',
      howPriceQuestion: actionPlan?.howPriceQuestion || '',
      link: actionPlan?.link || '',
    },
  })

  const triggerValidation = async () => {
    let valid = await trigger()

    if (isEmpty(actionPlan?.attachments) && isEmpty(files)) {
      valid = false
      setOpinionFileError(true)
    } else if (opinionFileError) {
      setOpinionFileError(false)
    }

    return valid
  }

  const onSubmit = async (data: QuestionFormSchemaType) => {
    const valid = await triggerValidation()

    const isEdit = !!actionPlan
    let actionPlanId = actionPlan?.id

    if (!valid) return

    try {
      setIsLoading(true)
      if (isEdit) {
        await service.dponet.questionnaires.updateActionPlan({
          quizTemplateId: questionnaireId,
          quizQuestionId: quizQuestionId,
          actionPlanId: actionPlan?.id,
          actionPlan: {
            ...data,
          },
        })
      } else {
        const response = await service.dponet.questionnaires.createActionPlan({
          quizTemplateId: questionnaireId,
          quizQuestionId: quizQuestionId,
          actionPlan: {
            ...data,
          },
        })
        actionPlanId = response?.data?.id
      }

      files &&
        (await service.dponet.questionnaires.addDocumentActionPlan({
          quizTemplateId: questionnaireId,
          quizQuestionId: quizQuestionId,
          actionPlanId,
          files,
        }))

      snackbar.open({
        message: `Plano de ação ${
          actionPlan ? 'editado' : 'criado'
        } com sucesso!`,
        variant: 'success',
      })
      redirectToMain()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const deleteDocument = async (documentId: number) => {
    try {
      setIsLoading(true)
      await service.dponet.questionnaires.deleteDocument({
        quizTemplateId: questionnaireId,
        quizQuestionId: quizQuestionId,
        actionPlanId: actionPlan?.id,
        documentId: documentId,
      })

      refresh()

      snackbar.open({
        message: 'Documento excluído com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const redirectToMain = () => {
    if (questionnaireId) {
      navigate(
        reverse(routes.app.questionnaires.edit, {
          questionnaireId: questionnaireId,
        })
      )
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formName}>
      <Box paddingY={8}>
        <Typography variant="h6">Plano de ação</Typography>
      </Box>
      <Paper component={Box} p={4}>
        <Grid container>
          <Grid item xs={12}>
            <Box>
              <FormLabel>O que (opcional)</FormLabel>
              <Box py={2}>
                <Typography variant="caption" color={'GrayText'}>
                  Ações concretas e objetivas que serão tomadas
                </Typography>
              </Box>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors?.whatQuestion}
                    helperText={errors?.whatQuestion?.message}
                    type="text"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={3}
                  />
                )}
                control={control}
                name="whatQuestion"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box paddingTop={4} marginRight={2}>
              <FormLabel>Como (opcional)</FormLabel>
              <Box py={2}>
                <Typography variant="caption" color={'GrayText'}>
                  Planejamento para execução da ação
                </Typography>
              </Box>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors?.howQuestion}
                    helperText={errors?.howQuestion?.message}
                    type="text"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={3}
                  />
                )}
                control={control}
                name="howQuestion"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box paddingTop={4} marginLeft={2}>
              <FormLabel>Por que (opcional)</FormLabel>
              <Box py={2}>
                <Typography variant="caption" color={'GrayText'}>
                  A meta que se objetiva com a ação
                </Typography>
              </Box>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors?.becauseQuestion}
                    helperText={errors?.becauseQuestion?.message}
                    type="text"
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={3}
                  />
                )}
                control={control}
                name="becauseQuestion"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box paddingTop={4} marginRight={2}>
              <FormLabel>Onde (opcional)</FormLabel>
              <Box py={2}>
                <Typography variant="caption" color={'GrayText'}>
                  Em qual setor a ação será realizada
                </Typography>
              </Box>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors?.whereQuestion}
                    helperText={errors?.whereQuestion?.message}
                    type="text"
                    variant="outlined"
                    fullWidth
                  />
                )}
                control={control}
                name="whereQuestion"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box paddingTop={4} marginLeft={2}>
              <FormLabel>Quanto (opcional)</FormLabel>
              <Box py={2}>
                <Typography variant="caption" color={'GrayText'}>
                  Impacto financeiro da ação
                </Typography>
              </Box>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors?.howPriceQuestion}
                    helperText={errors?.howPriceQuestion?.message}
                    type="text"
                    variant="outlined"
                    fullWidth
                  />
                )}
                control={control}
                name="howPriceQuestion"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box paddingY={4}>
              <FormLabel>Upload de documento e/ou link</FormLabel>
              <Box py={2}>
                <Typography variant="caption" color={'GrayText'}>
                  Link
                </Typography>
              </Box>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors?.link}
                    helperText={errors?.link?.message}
                    type="text"
                    variant="outlined"
                    fullWidth
                  />
                )}
                control={control}
                name="link"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <DocumentAttachment
              name="files"
              selectedFile={files}
              setSelectedFile={setFile}
              attachment={documents}
              description="Clique aqui para anexar arquivo"
              error={opinionFileError}
              deleteDocument={deleteDocument}
            />
          </Grid>
        </Grid>
      </Paper>
      <Box display="flex" justifyContent="end" marginTop={4} gap={2}>
        <Button variant="outlined" onClick={redirectToMain}>
          Voltar
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Salvar
        </Button>
      </Box>
    </form>
  )
}

export default ActionPlanForm
