import { useEffect, useState } from 'react'

import { FilterInput, Filters } from 'components'

import { FilterHookType } from 'types/filters.types'

const LegitimateInterestFilters = ({ filter }: { filter: FilterHookType }) => {
  const [shouldFetch, setShouldFetch] = useState<boolean>(false)

  useEffect(() => {
    if (filter.drawerOpen && !shouldFetch) setShouldFetch(true)
  }, [filter.drawerOpen])

  return (
    <Filters filter={filter}>
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Identificador', name: 'id' }}
      />
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Nome do processo', name: 'name' }}
      />
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Empresa', name: 'companyName' }}
      />
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Documento', name: 'companyDocument' }}
      />
      <FilterInput
        fieldConfigs={{ datePicker: true }}
        {...{ label: 'Criado em', name: 'updatedAt' }}
      />
    </Filters>
  )
}

export default LegitimateInterestFilters
