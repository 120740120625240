import { include } from 'named-urls'

const routes = {
  root: '/',
  app: include('/app', {
    all: '',
    organizations: include('organizations/', {
      all: '',
      dashboard: 'dashboard',
      update: ':organizationId/update',
      show: ':organizationId/show',
    }),
    profiles: include('profiles/', {
      all: '',
      new: 'new',
      update: ':profileId/update',
      show: ':profileId/show',
    }),
    tasks: include('tasks/', {
      all: '',
    }),
    companies: include('companies/', {
      all: '',
      update: ':organizationCompanyId/update',
    }),
    legalFrameworks: include('legal-frameworks/', {
      all: '',
    }),
    tags: include('tags/', {
      all: '',
      update: ':organizationTagId/update',
      show: ':organizationTagId/show',
    }),
    tickets: include('tickets/', {
      all: '',
      message: ':ticketId/messages',
    }),
    myTickets: include('my-tickets/', {
      all: '',
      message: ':ticketId/messages',
    }),
    firstAccess: 'first-access',
    invites: include('invites/', {
      outsourcedDpo: 'outsourced-dpo',
      observer: 'observer',
      organization: 'organization',
    }),
    forbidden: 'forbidden',
    notFound: 'not-found',
    privacyPortal: include('privacy-portal/', {
      all: '',
      serviceSettings: 'service-settings',
      editAnswer:
        'service-settings/company/:companyId/question/:questionId/answer/:answerId/edit',
      ticket: 'tickets',
      ticketMessage: ':ticketId/messages',
    }),
    incidents: include('incidents/', {
      all: '',
    }),
    incidentManegements: include('incident-managements/', {
      edit: ':incidentId/edit',
    }),
    questionnaires: include('questionnaires/', {
      all: '',
      edit: ':questionnaireId/edit',
      createQuestion: ':questionnaireId/quizQuestion',
      updateQuestion: ':questionnaireId/quizQuestion/:quizQuestionId/edit',
    }),
    faqs: include('faqs/', {
      all: '',
      show: ':faqId/show',
    }),
    legitimateInterest: include('legitimate-interest/', {
      all: '',
      show: ':dataProcessId/show',
      reviewClient: ':dataProcessId/review-client',
      reviewDpo: ':dataProcessId/review-dpo',
    }),
    dataProcesses: include('data-processes/', {
      templates: include('templates/', {
        all: '',
        edit: ':dataProcessTemplateId/edit',
        new: 'new',
        questions: include(':dataProcessTemplateId/questions/', {
          edit: 'edit',
        }),
      }),
    }),
  }),
  auth: include('/', {
    login: '',
    register: 'register',
    recoveryPassword: 'recovery-password',
    resetPassword: 'new-password/:resetPasswordToken',
    twoFactorAuthentication: 'two-factor-authentication/:accessToken',
    forbidden: 'forbidden',
    notFound: 'not-found',
  }),
}

export default routes
