import { Box, Link, Typography } from '@mui/material'
import { MUI } from 'components'
import { TicketAttachmentType } from './ticketAttachment.types'
import { useRef } from 'react'
import constants from 'constants/index'
import { AttachmentTable } from './components'

const TicketAttachment = ({
  attachments,
  setAttachments,
}: TicketAttachmentType) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setAttachments && attachments) {
      const newFiles: File[] = Array.from(event?.target?.files || [])
      setAttachments([...attachments, ...newFiles])

      if (fileInputRef.current) fileInputRef.current.value = ''
    }
  }

  const handleDetach = (index: number) => {
    const filteredAttachments = attachments.filter((_, i) => i !== index)
    setAttachments(filteredAttachments)
  }

  return (
    <Box>
      <MUI.LabelHtml htmlFor="attachment" $cursor="pointer">
        <Typography variant="subtitle1">
          Para anexar arquivos, <Link>Clique aqui</Link>
        </Typography>
        <Typography variant="subtitle2">
          Arquivos suportados: xlsx, xls, imagens, doc, docx, ppt, pptx, txt,
          pdf
        </Typography>
        <MUI.InputHtml
          ref={fileInputRef}
          id="attachment"
          name="attachment"
          type="file"
          multiple
          accept={constants.validations.ALL_FILE_TYPES}
          $display="none"
          onChange={handleFileChange}
        />
      </MUI.LabelHtml>

      {attachments.length > 0 && (
        <Box mt={4}>
          <AttachmentTable
            attachments={attachments}
            handleDetach={handleDetach}
          />
        </Box>
      )}
    </Box>
  )
}

export default TicketAttachment
