import { Box, Paper, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import Question from '../Question'

import { LiaQuestionType } from 'types/liaQuestion.types'
import { BalancingSafeguardingType } from './balancingSafeguarding.types'

import constants from 'constants/index'
import { QuestionsDpoFormType } from '../../schema'

const BalancingSafeguarding = ({
  questions,
  show = false,
}: BalancingSafeguardingType) => {
  const {
    LEGITIMATE_EXPECTATION,
    RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS,
    SAFEGUARDS_AND_MECHANISMS,
  } = constants.legitimateInterest.LIA_QUESTION_KINDS.ALL_KINDS

  const filterLegitimateExpectation = (questions: LiaQuestionType[]) => {
    return questions?.filter((question) => {
      return question?.kind === LEGITIMATE_EXPECTATION
    })
  }

  const filterQuestionsRisksImpactsFundamental = (
    questions: LiaQuestionType[]
  ) => {
    return questions?.filter((question) => {
      return question?.kind === RISKS_IMPACTS_FUNDAMENTAL_RIGHTS_FREEDOMS
    })
  }

  const filterQuestionsSafeguardsMechanisms = (
    questions: LiaQuestionType[]
  ) => {
    return questions?.filter((question) => {
      return question?.kind === SAFEGUARDS_AND_MECHANISMS
    })
  }

  const questionsMinimization = filterLegitimateExpectation(questions)
  const questionsRisksImpactsFundamental =
    filterQuestionsRisksImpactsFundamental(questions)
  const questionsSafeguardsMechanisms =
    filterQuestionsSafeguardsMechanisms(questions)

  const {
    control,
    formState: { errors },
  } = useFormContext<QuestionsDpoFormType>()

  return (
    <Paper component={Box} mb={3} p={4}>
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography fontWeight={700} color="primary">
          Legítima expectativa
        </Typography>
        {questionsMinimization?.map((question) => (
          <Question
            key={question?.id}
            question={question}
            control={control}
            errors={errors}
            disabled={show}
          />
        ))}
      </Box>
      <Box display="flex" flexDirection="column" gap={3} mt={6}>
        <Typography fontWeight={700} color="primary">
          Riscos e impactos aos direitos e liberdades fundamentais
        </Typography>
        {questionsRisksImpactsFundamental?.map((question) => (
          <Question
            key={question?.id}
            question={question}
            control={control}
            errors={errors}
            disabled={show}
          />
        ))}
      </Box>
      <Box display="flex" flexDirection="column" gap={3} mt={6}>
        <Typography fontWeight={700} color="primary">
          Salvaguardas e mecanismos de opt-out e de oposição
        </Typography>
        {questionsSafeguardsMechanisms?.map((question) => (
          <Question
            key={question?.id}
            question={question}
            control={control}
            errors={errors}
            disabled={show}
          />
        ))}
      </Box>
    </Paper>
  )
}

export default BalancingSafeguarding
