import {
  Box,
  Button,
  Hidden,
  StepConnector,
  StepIconProps,
  Typography,
} from '@mui/material'
import { Check as CheckIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { reverse } from 'named-urls'

import { StyledStep, StyledStepLabel, StyledStepper } from 'components'

import { useDataProcessQuestionTemplate } from 'hooks'
import BlueHexagonIcon from './components/BlueHexagonIcon'
import routes from 'constants/routes'

const Stepper = () => {
  const {
    activeStep,
    isCompleted,
    handleStep,
    handleBack,
    handleNext,
    dataProcessTemplate,
  } = useDataProcessQuestionTemplate()
  const navigate = useNavigate()

  const steps = [
    { name: 'Informações Gerais', kind: 'general_information' },
    { name: 'Dados Tratados', kind: 'data_collected' },
    { name: 'Salvaguarda e ciclo de vida', kind: 'life_cycle' },
    { name: 'Dados compartilhados', kind: 'data_treatment' },
  ]

  const dataProcessTemplateId = dataProcessTemplate?.id

  const handleReturn = () => {
    if (dataProcessTemplateId) {
      navigate(
        reverse(routes.app.dataProcesses.templates.edit, {
          dataProcessTemplateId: dataProcessTemplateId,
        })
      )
    }
  }

  const handleAction = () => (activeStep > 0 ? handleBack() : handleReturn())

  const Icon = (props: StepIconProps) => {
    let icon = Number(props.icon)
    icon -= 1

    const label = () => {
      if (props.completed) {
        return <CheckIcon />
      }

      return icon + 1
    }

    return (
      <BlueHexagonIcon
        display="flex"
        justifyContent="center"
        alignItems="center"
        mr={2}
        width={40}
        height={40}
      >
        <Typography
          color="primary"
          variant="body2"
          fontWeight={600}
          lineHeight={0}
        >
          {label()}
        </Typography>
      </BlueHexagonIcon>
    )
  }
  return (
    <Box p={5}>
      <StyledStepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<StepConnector sx={{ display: 'none' }} />}
      >
        {steps.map((step, index) => (
          <StyledStep
            key={step.kind}
            completed={isCompleted(index)}
            onClick={() => handleStep(index)}
            active
          >
            <StyledStepLabel
              StepIconComponent={Icon}
              StepIconProps={{
                completed: isCompleted(index),
                id: String(index),
              }}
            >
              <Typography color="primary" variant="body2" fontWeight={600}>
                {step.name}
              </Typography>
            </StyledStepLabel>
          </StyledStep>
        ))}
      </StyledStepper>
      <Hidden lgDown>
        <Box display="flex" gap={2} justifyContent="flex-end" mt={2}>
          <Button variant="outlined" onClick={handleAction}>
            Voltar
          </Button>
          <Button variant="contained" onClick={handleNext}>
            {activeStep === 3 ? 'Finalizar' : 'Próximo'}
          </Button>
        </Box>
      </Hidden>
    </Box>
  )
}

export default Stepper
