const ADVISOR_PROFILE = {
  VIEW: 'show_advisor_profile',
  MANAGE: 'update_advisor_profile',
}

const INCIDENTS = {
  LIST: 'list_incidents',
}

const LEGAL_FRAMEWORK = {
  MANAGE: 'change_status_data_process',
}

const ORGANIZATION = {
  MANAGE: 'update_organization',
}

const ORGANIZATION_COMPANY = {
  VIEW: 'show_organization_company',
  MANAGE: 'update_organization_company',
}

const ORGANIZATION_TAG = {
  VIEW: 'show_organization_tag',
  MANAGE: 'create_organization_tag',
}

const ORGANIZATION_USER = {
  VIEW: 'show_organization_user',
  LIST: 'list_organization_user',
  MANAGE: 'create_organization_user',
}

const QUESTIONNAIRES = {
  VIEW: 'show_organization_questionnaire',
  MANAGE: 'update_organization_questionnaire',
}

const PRIVACY_PORTAL_CONFIGURATION = {
  MANAGE_FORM: 'update_question_option_ticket',
  MANAGE_THEME: 'manage_privacy_portal_theme',
  VIEW_FORM: 'list_question_option_ticket',
}

const SOLICITATION_TICKET = {
  VIEW: 'show_solicitation_ticket',
  MANAGE: 'update_solicitation_ticket',
}

const TASKS = {
  VIEW: 'list_tasks',
  CREATE: 'create_task',
  UPDATE: 'update_task',
  DESTROY: 'destroy_task',
}

const JURIDIC_TICKET = {
  VIEW: 'show_juridic_ticket',
  MANAGE: 'update_juridic_ticket',
}

const CLIENT_PORTAL_THEME = {
  VIEW: 'show_client_portal_theme',
  MANAGE: 'update_client_portal_theme',
}

const OBSERVERS_ORGANIZATIONS = {
  MANAGE_OBSERVERS_INCIDENT: 'edit_allow_observers_incident',
  MANAGE_OBSERVERS_TICKET: 'edit_allow_observers_ticket',
}

const LEGITIMATE_INTEREST = {
  LIST: 'list_data_process_status_lia',
  SHOW: 'list_lia_questions_with_answers',
  MANAGE: 'reply_lia_questions_dpo',
}

const DATA_PROCESS_TEMPLATE = {
  MANAGE: 'create_data_process_template',
  VIEW: 'show_data_process_template',
}

export default {
  ADVISOR_PROFILE,
  CLIENT_PORTAL_THEME,
  DATA_PROCESS_TEMPLATE,
  INCIDENTS,
  JURIDIC_TICKET,
  LEGAL_FRAMEWORK,
  LEGITIMATE_INTEREST,
  OBSERVERS_ORGANIZATIONS,
  ORGANIZATION_COMPANY,
  ORGANIZATION_TAG,
  ORGANIZATION_USER,
  ORGANIZATION,
  PRIVACY_PORTAL_CONFIGURATION,
  QUESTIONNAIRES,
  SOLICITATION_TICKET,
  TASKS,
}
