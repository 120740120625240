import PerfectScrollbar from 'react-perfect-scrollbar'
import DOMPurify from 'dompurify'
import { isEmpty } from 'lodash'
import { Avatar, Box, Paper, Typography, colors } from '@mui/material'

import { TicketInnerMessageBox } from 'components'
import { TicketMessageAttachment } from './components'

import { DefaultTicketMessageType } from './defaultTicketMessage.types'

import { avatarLetters, formatters } from 'helpers'

const DefaultTicketMessage = ({ message }: DefaultTicketMessageType) => {
  const author = message?.author

  const messageSanitizer = DOMPurify.sanitize(message?.content, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  })

  return (
    <Paper elevation={1}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        bgcolor={colors.blue[50]}
        p={3}
      >
        <Box display="flex" gap={4}>
          <Avatar alt={author?.name}>{avatarLetters(author?.name)}</Avatar>
          <Box display="flex" flexDirection="column">
            <Typography fontWeight={700} color="primary">
              {author?.name || author?.organization}
            </Typography>

            <Typography variant="caption" color="text.secondary">
              {formatters.date.ptBrFormatDateTime(message.createdAt)}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box marginX={3} paddingY={5}>
        <PerfectScrollbar>
          <TicketInnerMessageBox
            dangerouslySetInnerHTML={{
              __html: messageSanitizer,
            }}
          />
        </PerfectScrollbar>
      </Box>

      <PerfectScrollbar>
        {!isEmpty(message?.attachments) && (
          <Box display="flex" pb={2} pl={3} gap={3}>
            {message?.attachments?.map((attachment) => (
              <TicketMessageAttachment
                key={attachment?.name}
                name={attachment?.name}
                url={attachment?.url}
              />
            ))}
          </Box>
        )}
      </PerfectScrollbar>
    </Paper>
  )
}

export default DefaultTicketMessage
