import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { ListPageStructure, LoadingFeedback, PageHead } from 'components'
import { ActionPlanForm, QuestionForm } from './components'

import { useFetch } from 'hooks'

import service from 'service'

const EditQuestion = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { questionnaireId, quizQuestionId } = useParams()

  const createQuestionFormName = 'edit-quiz-question-form'

  const { response, loading, refresh } = useFetch(
    service.dponet.questionnaires.showQuestion,
    {
      quizTemplateId: questionnaireId,
      quizQuestionId: quizQuestionId,
    },
    []
  )

  const quizQuestionTemplate = response?.data?.quizQuestionTemplate
  const actionPlan =
    response?.data?.quizQuestionTemplate?.questionPlanActionEvidency
  const documents = response?.data?.quizQuestionTemplate?.attachments

  return (
    <ListPageStructure>
      <LoadingFeedback open={isLoading || loading} />
      <PageHead name="Editar pergunta" />

      {!loading && (
        <>
          <QuestionForm
            questionnaireId={questionnaireId}
            quizQuestionId={quizQuestionId}
            quizQuestionData={quizQuestionTemplate}
            setIsLoading={setIsLoading}
            formName={createQuestionFormName}
          />

          <ActionPlanForm
            actionPlan={actionPlan}
            documents={documents}
            questionnaireId={questionnaireId}
            quizQuestionId={quizQuestionId}
            setIsLoading={setIsLoading}
            formName={createQuestionFormName}
            refresh={refresh}
          />
        </>
      )}
    </ListPageStructure>
  )
}

export default EditQuestion
