import { FormControl as FormControlMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

type FormControlType = {
  $alignItems?: Property.AlignItems
  $backgroundColor?: Property.BackgroundColor
  $border?: Property.Border<TLength>
  $borderRadius?: Property.BorderRadius<TLength>
  $color?: Property.Color
  $cursor?: Property.Cursor
  $display?: Property.Display
  $flexDirection?: Property.FlexDirection
  $gap?: Property.Gap<TLength>
  $height?: Property.Height<TLength>
  $justifyContent?: Property.JustifyContent
  $p?: Property.Padding<TLength>
  $textAlign?: Property.TextAlign
  $width?: Property.Width<TLength>
}

const FormControl = styled(FormControlMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<FormControlType>(
  ({
    $alignItems,
    $backgroundColor,
    $border,
    $borderRadius,
    $color,
    $cursor,
    $display,
    $flexDirection,
    $gap,
    $height,
    $justifyContent,
    $p,
    $textAlign,
    $width,
    theme,
  }) => ({
    alignItems: $alignItems,
    backgroundColor: $backgroundColor,
    border: $border,
    borderRadius: $borderRadius,
    color: $color,
    cursor: $cursor,
    display: $display,
    flexDirection: $flexDirection,
    gap: $gap && theme.spacing($gap),
    height: $height,
    justifyContent: $justifyContent,
    padding: $p && theme.spacing($p),
    textAlign: $textAlign,
    width: $width,
  })
)

export default FormControl
