/* eslint-disable @typescript-eslint/no-explicit-any */
const MAX_FILE_SIZE = 10 * 1024 * 1024
const IMAGE_FILE_TYPES = '.jpg,.gif,.png,.jpeg,.svg,.webp'
const DOCUMENT_FILE_TYPES =
  '.doc,.docx,.ppt,.pptx,.txt,.pdf,.xls,.xlsx,.csv,.ods,.odt,.rtf'

const UNKNOWN_FILE_MIME_TYPE = 'application/octet-stream'

const ALL_FILE_TYPES = `${IMAGE_FILE_TYPES},${DOCUMENT_FILE_TYPES}`

const IMAGE_FILE_MIMES_TYPES = [
  'image/jpeg', // .jpg, .jpeg
  'image/gif', // .gif
  'image/png', // .png
  'image/svg+xml', // .svg
  'image/webp', // .webp
]

const DOCUMENT_FILE_MIMES_TYPES = [
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'text/plain', // .txt
  'application/pdf', // .pdf
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'text/csv', // .csv
  'application/vnd.oasis.opendocument.spreadsheet', // .ods
  'application/vnd.oasis.opendocument.text', // .odt
  'application/rtf', // .rtf
  // UNKNOWN_FILE_MIME_TYPE, // Desconhecido (Não montado)
]

const ALL_FILE_MIMES_TYPES = [
  ...IMAGE_FILE_MIMES_TYPES,
  ...DOCUMENT_FILE_MIMES_TYPES,
]

const BYTES_UNIT = 'bytes'
const KILOBYTE_UNIT = 'kB'
const MEGABYTE_UNIT = 'MB'
const GIGABYTE_UNIT = 'GB'

const STORAGE_MEASUREMENT_UNITS = [
  BYTES_UNIT,
  KILOBYTE_UNIT,
  MEGABYTE_UNIT,
  GIGABYTE_UNIT,
] as const

const BYTE_UNIT_EXPONENT = {
  [BYTES_UNIT]: 2 ** 1,
  [KILOBYTE_UNIT]: 2 ** 10,
  [MEGABYTE_UNIT]: 2 ** 20,
  [GIGABYTE_UNIT]: 2 ** 30,
}

// Caso for utilizar algum parâmetro de arquivo que não exista na lista, favor inclua para validação!
const MIME_TYPE_VALIDATION: any = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  svg: 'image/svg+xml',
  webp: 'image/webp',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  txt: 'text/plain',
  pdf: 'application/pdf',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'text/csv',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  odt: 'application/vnd.oasis.opendocument.text',
  rtf: 'application/rtf',
}

const OPENABLE_FILE_MIME_TYPES = [
  'image/jpeg', // .jpg, .jpeg
  'image/gif', // .gif
  'image/png', // .png
  'image/svg+xml', // .svg
  'image/webp', // .webp
  'application/pdf', // .pdf
]

export type StorageMeasurementUnitType =
  (typeof STORAGE_MEASUREMENT_UNITS)[number]

export default {
  ALL_FILE_MIMES_TYPES,
  ALL_FILE_TYPES,
  BYTE_UNIT_EXPONENT,
  DOCUMENT_FILE_MIMES_TYPES,
  DOCUMENT_FILE_TYPES,
  IMAGE_FILE_TYPES,
  IMAGE_FILE_MIMES_TYPES,
  MAX_FILE_SIZE,
  MIME_TYPE_VALIDATION,
  UNKNOWN_FILE_MIME_TYPE,
  OPENABLE_FILE_MIME_TYPES,
}
