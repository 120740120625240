import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'

import { Card, FileUploadInput, LabelDescription } from 'components'
import IncidentTextArea from '../IncidentTextArea'

import { useIncidentManegement } from 'hooks'

import constants from 'constants/index'
import schema from './schema'

const DetectionStep = () => {
  const { incident } = useIncidentManegement()

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      detectionAttachments: incident?.detectionAttachmentsList,
    },
  })

  return (
    <Card title="Detecção do Incidente">
      <FormProvider {...formMethods}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <IncidentTextArea
              label="Descreva como ficou sabendo a respeito do incidente:"
              value={incident?.discoveryDescription}
            />
          </Grid>
          <Grid item xs={12}>
            <LabelDescription title="Anexo">
              <FileUploadInput
                controlName="detectionAttachments"
                title="Arraste e solte ou selecione o documento a ser anexado"
                accept={constants.validations.ALL_FILE_TYPES}
                multiple
                controllerBar
                disabled
              />
            </LabelDescription>
          </Grid>
        </Grid>
      </FormProvider>
    </Card>
  )
}

export default DetectionStep
