import { Fragment } from 'react'
import {
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  Table as TableComponent,
  TableRow,
} from '@mui/material'

import { Permitted } from 'components'
import { ChipStatus, Actions } from './components'

import { LegitimateInterestTableType } from './LegitimateInterestTabletypes'

import permissionTags from 'constants/permissionTags'
import date from 'helpers/formatters/date'

const LegitimateInterestTable = ({
  dataProcessesWithLia,
}: LegitimateInterestTableType) => {
  return (
    <Fragment>
      <TableContainer>
        <TableComponent aria-label="Listagem processos com legítimo interesse">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nome do processo</TableCell>
              <TableCell>Empresa</TableCell>
              <TableCell>Documento</TableCell>
              <TableCell align="center">Criado em</TableCell>
              <TableCell align="center">Status</TableCell>
              <Permitted
                someTags={[
                  permissionTags.LEGITIMATE_INTEREST.MANAGE,
                  permissionTags.LEGITIMATE_INTEREST.SHOW,
                ]}
              >
                <TableCell align="right">Ações</TableCell>
              </Permitted>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataProcessesWithLia?.map((dataProcess) => (
              <TableRow key={dataProcess?.id}>
                <TableCell>{dataProcess?.id}</TableCell>
                <TableCell>{dataProcess?.name}</TableCell>
                <TableCell>{dataProcess?.company?.name}</TableCell>
                <TableCell>{dataProcess?.company?.document}</TableCell>
                <TableCell align="center">
                  {date.ptBrFormatDate(dataProcess?.updatedAt)}
                </TableCell>
                <TableCell align="center">
                  <ChipStatus statusId={dataProcess?.statusId} />
                </TableCell>
                <Permitted
                  someTags={[
                    permissionTags.LEGITIMATE_INTEREST.MANAGE,
                    permissionTags.LEGITIMATE_INTEREST.SHOW,
                  ]}
                >
                  <TableCell align="right">
                    <Actions dataProcess={dataProcess} />
                  </TableCell>
                </Permitted>
              </TableRow>
            ))}
          </TableBody>
        </TableComponent>
      </TableContainer>
    </Fragment>
  )
}

export default LegitimateInterestTable
