import { useState } from 'react'
import { Box, Button, Grid, Skeleton } from '@mui/material'
import { PlusCircle as PlusCircleIcon } from 'react-feather'

import { AddFieldDialog, Question } from './components'

import { QuestionsType } from './questions.types'
import { DataProcessTemplateQuestionType } from 'types/dataProcessTemplate.types'

import { useDataProcessQuestionTemplate, useFetch, usePermission } from 'hooks'

import service from 'service'
import permissionTags from 'constants/permissionTags'

const Questions = ({ step = 'general_information' }: QuestionsType) => {
  const [open, setOpen] = useState(false)

  const { dataProcessTemplate } = useDataProcessQuestionTemplate()
  const { isPermitted } = usePermission()

  const { response, refresh, loading } = useFetch(
    service.dponet.dataProcessTemplateQuestion.get,
    { dataProcessTemplateId: dataProcessTemplate?.id, params: { step } },
    []
  )

  const dataProcessTemplateQuestions: DataProcessTemplateQuestionType[] =
    response?.data?.dataProcessTemplateQuestions || []

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Grid container spacing={4}>
      {dataProcessTemplate?.owner &&
        isPermitted(permissionTags.DATA_PROCESS_TEMPLATE.MANAGE) && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlusCircleIcon />}
              fullWidth
              size="large"
              onClick={handleOpen}
            >
              Adicionar Campo
            </Button>
          </Grid>
        )}

      <Grid item xs={12}>
        {!loading ? (
          <Box display="flex" flexDirection="column" gap={3}>
            {dataProcessTemplateQuestions.map((question) => (
              <Question
                key={question.id}
                dataProcessTemplateQuestion={question}
                refresh={refresh}
              />
            ))}
          </Box>
        ) : (
          <Skeleton height={200} variant="rounded" />
        )}
      </Grid>
      <AddFieldDialog
        refresh={refresh}
        open={open}
        onClose={handleClose}
        step={step}
      />
    </Grid>
  )
}

export default Questions
