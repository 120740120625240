import { useEffect, useState } from 'react'

import { validates } from 'helpers'

import { DefaultAttachmentType } from 'types/file.types'

import constants from 'constants/index'

const useConvertToFile = (
  attachments?: DefaultAttachmentType[] | DefaultAttachmentType | null
) => {
  const [loading, setLoading] = useState(true)
  const [file, setFile] = useState<File | null>(null)
  const [fileList, setFileList] = useState<FileList | null>(null)

  useEffect(() => {
    const convertToFile = async (attachment: DefaultAttachmentType) => {
      const response = await fetch(attachment.url)
      const blob = await response.blob()

      let mimeType = blob.type
      if (mimeType === constants.validations.UNKNOWN_FILE_MIME_TYPE)
        mimeType = validates.file.getMimeTypeFromExtension(attachment.name)

      return new File([blob], attachment.name, { type: mimeType })
    }

    const convertAll = async () => {
      setLoading(true)

      const attachmentArray = Array.isArray(attachments)
        ? attachments.filter(Boolean)
        : attachments
        ? [attachments]
        : []

      const filePromises =
        attachmentArray?.map((attachment) => convertToFile(attachment)) ?? []
      const convertedFiles = await Promise.all(filePromises)

      const dataTransfer = new DataTransfer()
      convertedFiles.forEach((convertedFile) => {
        dataTransfer.items.add(convertedFile)
        setFile(convertedFile)
      })

      setFileList(dataTransfer.files)
      setLoading(false)
    }

    if (attachments) {
      convertAll()
    } else {
      setLoading(false)
    }
  }, [attachments])

  return { file, fileList, loading }
}

export default useConvertToFile
