import * as yup from 'yup'

import { formatters, validates } from 'helpers'

import constants from 'constants/index'

const { ALL_FILE_TYPES, ALL_FILE_MIMES_TYPES } = constants.validations

const schema = yup.object({
  detectionAttachments: yup
    .mixed<FileList>()
    .test('fileSize', 'A imagem deve ter no máximo 10 MB', (value?: FileList) =>
      validates.file.size('MB', 10, value)
    )
    .test(
      'fileType',
      `Somente arquivos ${formatters.file.allowedTypes(
        ALL_FILE_TYPES
      )} são permitidos`,
      (value?: FileList) => validates.file.type(ALL_FILE_MIMES_TYPES, value)
    )
    .test(
      'uniqueFilenames',
      'Não é permitido enviar arquivos com o mesmo nome',
      (value?: FileList) => validates.file.uniqueFile(value)
    ),
})

export default schema
