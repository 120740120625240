import { Fragment, useEffect, useState } from 'react'
import { Box, Button, Paper, TextField, Typography } from '@mui/material'
import { Check as Checkicon, X as XIcon, User as UserIcon } from 'react-feather'
import { Controller, useFormContext } from 'react-hook-form'

import { PaperHeader, MUI } from 'components'

import {
  AnswerDpoQuestionType,
  QuestionIsPresentType,
  OrderType,
} from 'types/liaAnswers.types'
import { LiaAnswersAnalysisDisapprovedType } from './LiaAnswersAnalysisDisapproved.types'

import dponet_logo from 'images/dponet_logo.png'
import {
  AnswersLiaReviewFormType,
  AnswersLiaReviewKeyType,
} from '../../schemaAnswersAnalysis'
import { formatters } from 'helpers'
import constants from 'constants/index'
import { orderBy } from 'lodash'

const LiaAnswersAnalysisDisapproved = ({
  liaAnswers,
}: LiaAnswersAnalysisDisapprovedType) => {
  const [approved, setApproved] = useState(false)
  const [disapproved, setDisapproved] = useState(false)

  const { REVIEW_DISAPPROVED, DISAPPROVED } =
    constants.legitimateInterest.STATUSES_LIA_ANSWER

  const questionOrder = liaAnswers?.[0]?.liaQuestion?.order

  const answerDpoQuestion =
    `answerDpoQuestion${questionOrder}` as AnswerDpoQuestionType

  const order = `order${questionOrder}` as OrderType

  const questionIsPresent =
    `question${questionOrder}IsPresent` as QuestionIsPresentType

  const {
    control,
    formState: { errors },
    setValue,
    resetField,
    clearErrors,
  } = useFormContext<AnswersLiaReviewFormType>()

  const answerDpoQuestionWasApproved: AnswersLiaReviewKeyType = `${answerDpoQuestion}WasApproved`

  const handleApproved = () => {
    setApproved(true)
    setValue(order, liaAnswers?.[0]?.id)
    setValue(answerDpoQuestionWasApproved, true)
    clearErrors(answerDpoQuestionWasApproved)
    setDisapproved(false)
  }

  const handleDisapproved = () => {
    setDisapproved(true)
    setValue(order, liaAnswers?.[0]?.id)
    setValue(answerDpoQuestionWasApproved, false)
    clearErrors(answerDpoQuestionWasApproved)
    setApproved(false)
  }

  const cancelApproved = () => {
    setApproved(false)
    resetField(answerDpoQuestionWasApproved)
  }

  const cancelDisapproved = () => {
    setDisapproved(false)
    resetField(answerDpoQuestionWasApproved)
    resetField(answerDpoQuestion)
  }

  useEffect(() => {
    setValue(questionIsPresent, true)
  }, [liaAnswers])

  const filterReviewDisapprovedAnswers = () => {
    return liaAnswers?.filter((answer) => {
      return answer.status === REVIEW_DISAPPROVED
    })
  }

  const filterDisapprovedAnswers = () => {
    return liaAnswers?.filter((answer) => {
      return answer.status === DISAPPROVED
    })
  }

  return (
    <Paper component={Box} mb={3}>
      <PaperHeader
        title={`${liaAnswers?.[0]?.liaQuestion?.order} - ${liaAnswers?.[0]?.liaQuestion?.text}`}
        px={3}
        actionButton={
          <Box display="flex" flexDirection="column">
            <Box display="flex" gap={2} justifyContent="center">
              {approved ? (
                <Button
                  variant="contained"
                  color="tertiary"
                  onClick={cancelApproved}
                >
                  Aprovado
                </Button>
              ) : (
                <Button variant="outlined" onClick={handleApproved}>
                  <Checkicon size={18} />
                </Button>
              )}
              {disapproved ? (
                <Button
                  variant="contained"
                  color="error"
                  onClick={cancelDisapproved}
                >
                  Reprovado
                </Button>
              ) : (
                <Button variant="outlined" onClick={handleDisapproved}>
                  <XIcon size={18} />
                </Button>
              )}
            </Box>
            {!!errors?.[answerDpoQuestionWasApproved] && (
              <Typography color="tomato" variant="body1">
                {errors?.[answerDpoQuestionWasApproved]?.message}
              </Typography>
            )}
          </Box>
        }
      />
      <Box p={4}>
        {filterReviewDisapprovedAnswers()?.map((answer) => (
          <Fragment key={answer?.id}>
            <Box
              display="flex"
              bgcolor="#B8DAF7"
              justifyContent="space-between"
              p={2}
              alignItems="center"
            >
              <Box display="flex" alignItems="center" gap={2} minHeight={70}>
                <Box
                  bgcolor="white"
                  display="flex"
                  justifyContent="center"
                  width={50}
                  height={50}
                  borderRadius={50}
                  alignItems="center"
                >
                  <UserIcon size={25} />
                </Box>
                <Typography fontWeight={700} color="primary">
                  Cliente
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography color="primary" fontWeight={700}>
                  Última atualização
                </Typography>
                <Typography>
                  {formatters.date.ptBrFormatDate(answer?.updatedAt)}
                </Typography>
              </Box>
            </Box>
            <Box border={2} borderColor="whitesmoke" minHeight={70} p={2}>
              <Typography variant="body1">{answer?.answer}</Typography>
            </Box>
          </Fragment>
        ))}
        {disapproved && (
          <>
            <Box
              display="flex"
              bgcolor="#E3FFBB"
              justifyContent="space-between"
              mt={2}
              p={2}
              alignItems="center"
            >
              <Box display="flex" alignItems="center" gap={2} minHeight={70}>
                <Box
                  bgcolor="white"
                  display="flex"
                  justifyContent="center"
                  width={50}
                  height={50}
                  borderRadius={50}
                  alignItems="center"
                >
                  <MUI.Img
                    src={dponet_logo}
                    $height="90%"
                    $width="90%"
                    $objectFit="cover"
                    $objectPosition="-3% 1%"
                  />
                </Box>
                <Typography fontWeight={700} color="primary">
                  DPO
                </Typography>
              </Box>
            </Box>
            <Box>
              <Controller
                name={answerDpoQuestion}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors?.[answerDpoQuestion]}
                    helperText={<>{errors?.[answerDpoQuestion]?.message}</>}
                    fullWidth
                    variant="outlined"
                    multiline
                    minRows={2}
                  />
                )}
              />
            </Box>
          </>
        )}
        <Box mt={4}>
          <Typography fontWeight={700}>Respostas anteriores...</Typography>
        </Box>
        {orderBy(filterDisapprovedAnswers(), 'updatedAt', 'desc')?.map(
          (answer) => (
            <Fragment key={answer?.id}>
              <Box
                display="flex"
                bgcolor="#E3FFBB"
                justifyContent="space-between"
                mt={2}
                p={2}
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gap={2} minHeight={70}>
                  <Box
                    bgcolor="white"
                    display="flex"
                    justifyContent="center"
                    width={50}
                    height={50}
                    borderRadius={50}
                    alignItems="center"
                  >
                    <MUI.Img
                      src={dponet_logo}
                      $height="90%"
                      $width="90%"
                      $objectFit="cover"
                      $objectPosition="-3% 1%"
                    />
                  </Box>
                  <Typography fontWeight={700} color="primary">
                    DPO
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography color="primary" fontWeight={700}>
                    Última atualização
                  </Typography>
                  <Typography>
                    {formatters.date.ptBrFormatDate(answer?.updatedAt)}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography
                  border={2}
                  borderColor="whitesmoke"
                  minHeight={70}
                  p={2}
                  variant="body1"
                >
                  {answer?.reasonRejection}
                </Typography>
              </Box>
              <Box
                display="flex"
                bgcolor="#B8DAF7"
                justifyContent="space-between"
                p={2}
                alignItems="center"
                mt={2}
              >
                <Box display="flex" alignItems="center" gap={2} minHeight={70}>
                  <Box
                    bgcolor="white"
                    display="flex"
                    justifyContent="center"
                    width={50}
                    height={50}
                    borderRadius={50}
                    alignItems="center"
                  >
                    <UserIcon size={25} />
                  </Box>
                  <Typography fontWeight={700} color="primary">
                    Cliente
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography color="primary" fontWeight={700}>
                    Última atualização
                  </Typography>
                  <Typography>
                    {formatters.date.ptBrFormatDate(answer?.updatedAt)}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography
                  border={2}
                  borderColor="whitesmoke"
                  minHeight={70}
                  p={2}
                  variant="body1"
                >
                  {answer?.answer}
                </Typography>
              </Box>
            </Fragment>
          )
        )}
      </Box>
    </Paper>
  )
}

export default LiaAnswersAnalysisDisapproved
