import { useEffect, useState } from 'react'
import { Box, Button, Grid } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { first, isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'

import {
  BalancingSafeguarding,
  ConclusionLia,
  Finality,
  Necessity,
  StepperLia,
} from './components'

import { Permitted } from 'components'

import { useSnackbar, useLegitimateInterest } from 'hooks'

import { LiaQuestionType } from 'types/liaQuestion.types'
import { LiaAnswerType } from 'types/liaAnswers.types'
import { QuestionsDpoType } from './questionsDpo.types'

import constants from 'constants/index'
import schema, { QuestionsDpoFormType } from './schema'
import requiredSchema from './requiredSchema'
import service from 'service'
import { legitimateInterest } from 'helpers/index'
import errorMessage from 'helpers/formatters/errorMessage'
import routes from 'constants/routes'

const QuestionsDpo = ({
  onlyQuestions,
  liaQuestionsWithAnswers,
  companyCity,
  createdAt,
  refresh,
  answersClientApproved,
  dataProcess,
  show = false,
}: QuestionsDpoType) => {
  const [loading, setLoading] = useState(false)
  const {
    activeStep,
    setActiveStep,
    setStepBalancingSafeguardingCompleted,
    setStepBalancingSafeguardingIncomplete,
    setStepFinalityCompleted,
    setStepFinalityIncomplete,
    setStepNecessityCompleted,
    setStepNecessityIncomplete,
  } = useLegitimateInterest()

  const { snackbar } = useSnackbar()

  const navigate = useNavigate()

  const { FINALITY, NECESSITY, BALANCING_SAFEGUARDING } =
    constants.legitimateInterest.STEPS_DPO

  const { APPROVED } = constants.legitimateInterest.STATUSES_LIA
  const {
    FINALITY_KINDS,
    NECESSITY_KINDS,
    BALANCING_SAFEGUARDING_KINDS,
    CONCLUSION_LIA,
  } = constants.legitimateInterest.LIA_QUESTION_KINDS

  const filterFinalityQuestions = (questions: LiaQuestionType[]) => {
    return questions?.filter((question) =>
      FINALITY_KINDS.includes(question?.kind)
    )
  }

  const filterNecessityQuestions = (questions: LiaQuestionType[]) => {
    return questions?.filter((question) =>
      NECESSITY_KINDS.includes(question?.kind)
    )
  }

  const filterBalancingSafeguardingQuestions = (
    questions: LiaQuestionType[]
  ) => {
    return questions?.filter((question) =>
      BALANCING_SAFEGUARDING_KINDS.includes(question?.kind)
    )
  }

  const filterConclusionQuestions = (questions: LiaQuestionType[]) => {
    return questions?.filter((question) => CONCLUSION_LIA === question?.kind)
  }

  const finalityQuestions = filterFinalityQuestions(onlyQuestions)
  const necessityQuestions = filterNecessityQuestions(onlyQuestions)
  const balancingSafeguardingQuestions =
    filterBalancingSafeguardingQuestions(onlyQuestions)
  const conclusionQuestion = filterConclusionQuestions(onlyQuestions)

  const finalityQuestionsWithAnswers = filterFinalityQuestions(
    liaQuestionsWithAnswers
  )
  const necessityQuestionsWithAnswers = filterNecessityQuestions(
    liaQuestionsWithAnswers
  )
  const balancingSafeguardingQuestionsWithAnswers =
    filterBalancingSafeguardingQuestions(liaQuestionsWithAnswers)

  const verifyAnswersCompleted = (
    answers: (LiaAnswerType | undefined)[],
    questionsLength: number
  ) => {
    const answerCompleted = answers?.filter(
      (answer) => !isEmpty(answer?.answer)
    )
    return answerCompleted?.length === questionsLength
  }

  const verifyAnswersIncomplete = (
    answers: (LiaAnswerType | undefined)[],
    questionsLength: number
  ) => {
    const answeredAnswers = answers?.filter(
      (answer) => !isEmpty(answer?.answer)
    )
    if (isEmpty(answeredAnswers)) return false
    return answeredAnswers?.length < questionsLength
  }

  const finalityAnswers = finalityQuestionsWithAnswers?.flatMap(
    (question) => question.liaAnswers
  )

  const necessityAnswers = necessityQuestionsWithAnswers?.flatMap(
    (question) => question.liaAnswers
  )
  const balancingSafeguardingAnswers =
    balancingSafeguardingQuestionsWithAnswers?.flatMap(
      (question) => question.liaAnswers
    )

  const findAnswerDpoByOrder = (order: number) => {
    const answer = liaQuestionsWithAnswers?.flatMap((question) => {
      return question?.liaAnswers?.filter(
        (answer) => answer.liaQuestion?.order === order
      )
    })

    return first(answer)
  }

  const findAnswerClientByOrder = (order: number) => {
    const answer = answersClientApproved?.filter((answer) => {
      return answer.liaQuestion?.order === order
    })

    return first(answer)
  }

  const handleBack = () => {
    if (activeStep === 0) return
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      dataProcess?.statusId === APPROVED ? requiredSchema : schema
    ),
    defaultValues: {
      questionDpo1: findAnswerDpoByOrder(1)?.answer || '',
      questionDpo2: findAnswerDpoByOrder(2)?.answer || '',
      questionDpo3: findAnswerDpoByOrder(3)?.answer || '',
      questionDpo4: findAnswerDpoByOrder(4)?.answer || '',
      questionDpo5: findAnswerDpoByOrder(5)?.answer || '',
      questionDpo6: findAnswerDpoByOrder(6)?.answer || '',
      questionDpo7: findAnswerDpoByOrder(7)?.answer || '',
      questionDpo8: findAnswerDpoByOrder(8)?.answer || '',
      questionDpo9:
        findAnswerDpoByOrder(9)?.answer ||
        findAnswerClientByOrder(1)?.answer ||
        '',
      questionDpo10: findAnswerDpoByOrder(10)?.answer || '',
      questionDpo11: findAnswerDpoByOrder(11)?.answer || '',
      questionDpo12: findAnswerDpoByOrder(12)?.answer || '',
      questionDpo13: findAnswerDpoByOrder(13)?.answer || '',
      questionDpo14: findAnswerDpoByOrder(14)?.answer || '',
      questionDpo15:
        findAnswerDpoByOrder(15)?.answer ||
        findAnswerClientByOrder(2)?.answer ||
        '',
      questionDpo16:
        findAnswerDpoByOrder(16)?.answer ||
        findAnswerClientByOrder(3)?.answer ||
        '',
      questionDpo17:
        findAnswerDpoByOrder(17)?.answer ||
        findAnswerClientByOrder(4)?.answer ||
        '',
      questionDpo18: findAnswerDpoByOrder(18)?.answer || '',
      questionDpo19: findAnswerDpoByOrder(19)?.answer || '',
      questionDpo20: findAnswerDpoByOrder(20)?.answer || '',
      questionDpo21: findAnswerDpoByOrder(21)?.answer || '',
      questionDpo22: findAnswerDpoByOrder(22)?.answer || '',
      questionDpo23:
        findAnswerDpoByOrder(23)?.answer ||
        findAnswerClientByOrder(5)?.answer ||
        '',
      questionDpo24:
        findAnswerDpoByOrder(24)?.answer ||
        findAnswerClientByOrder(6)?.answer ||
        '',
      questionDpo25: findAnswerDpoByOrder(25)?.answer || '',
      questionDpo26:
        findAnswerDpoByOrder(26)?.answer ||
        findAnswerClientByOrder(7)?.answer ||
        '',
      questionDpo27:
        findAnswerDpoByOrder(27)?.answer ||
        findAnswerClientByOrder(8)?.answer ||
        '',
      questionDpo28: findAnswerDpoByOrder(28)?.answer || '',
      questionDpo29: findAnswerDpoByOrder(29)?.answer || '',
      questionDpo30: findAnswerDpoByOrder(30)?.answer || '',
      questionDpo31: findAnswerDpoByOrder(31)?.answer || '',
      questionDpo32: findAnswerDpoByOrder(32)?.answer || '',
      questionDpo33:
        findAnswerDpoByOrder(33)?.answer ||
        findAnswerClientByOrder(9)?.answer ||
        '',
      questionDpo34:
        findAnswerDpoByOrder(34)?.answer ||
        findAnswerClientByOrder(10)?.answer ||
        '',
      questionDpo35:
        findAnswerDpoByOrder(35)?.answer ||
        findAnswerClientByOrder(11)?.answer ||
        '',
      questionDpo36: findAnswerDpoByOrder(36)?.answer || '',
      questionDpo37: findAnswerDpoByOrder(37)?.answer || 'yes',
      questionDpo38: findAnswerDpoByOrder(38)?.answer || 'yes',
      questionDpo39: findAnswerDpoByOrder(39)?.answer || '',
      questionDpo40: new Date(findAnswerDpoByOrder(40)?.answer || new Date()),
      questionDpo41: findAnswerDpoByOrder(41)?.answer || companyCity || '',
    },
  })

  const LegalFramworksName = dataProcess?.legalFrameworks.map(
    (legalFramework) => legalFramework.name
  )

  const onSubmit = async (data: QuestionsDpoFormType) => {
    setLoading(true)
    const answersDpo = legitimateInterest.mountDpoReplyLia(
      data,
      activeStep,
      LegalFramworksName
    )
    try {
      if (activeStep === 3) {
        await service.dponet.liaAnswer.replyDpo({
          answersDpo,
          dataProcessId: dataProcess?.id,
          conclusionStep: true,
        })
        navigate(routes.app.legitimateInterest.all)
      } else {
        await service.dponet.liaAnswer.replyDpo({
          answersDpo,
          dataProcessId: dataProcess?.id,
        })
        refresh()
      }
    } catch (error) {
      snackbar.open({
        message: errorMessage(error),
        variant: 'error',
      })
    }
    setLoading(false)
  }

  const handleNext = async () => {
    if (loading) return

    if (!show) {
      const valid = await methods.trigger()

      if (!valid) return

      const values = methods.getValues()

      await onSubmit(values)
    }

    if (activeStep === 3) return

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  useEffect(() => {
    setStepFinalityCompleted(
      verifyAnswersCompleted(finalityAnswers, finalityQuestions?.length)
    )
    setStepNecessityCompleted(
      verifyAnswersCompleted(necessityAnswers, necessityQuestions?.length)
    )
    setStepBalancingSafeguardingCompleted(
      verifyAnswersCompleted(
        balancingSafeguardingAnswers,
        balancingSafeguardingQuestions?.length
      )
    )
    setStepFinalityIncomplete(
      verifyAnswersIncomplete(finalityAnswers, finalityQuestions?.length)
    )
    setStepNecessityIncomplete(
      verifyAnswersIncomplete(necessityAnswers, necessityQuestions?.length)
    )
    setStepBalancingSafeguardingIncomplete(
      verifyAnswersIncomplete(
        balancingSafeguardingAnswers,
        balancingSafeguardingQuestions?.length
      )
    )
  }, [liaQuestionsWithAnswers])

  return (
    <Grid container spacing={2}>
      <Grid item xl={9} lg={9} md={8} xs={12}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {activeStep === FINALITY && (
              <Finality questions={finalityQuestions} show={show} />
            )}
            {activeStep === NECESSITY && (
              <Necessity questions={necessityQuestions} show={show} />
            )}
            {activeStep === BALANCING_SAFEGUARDING && (
              <BalancingSafeguarding
                questions={balancingSafeguardingQuestions}
                show={show}
              />
            )}
            {activeStep === 3 && (
              <ConclusionLia
                createdAt={createdAt}
                show={show}
                questions={conclusionQuestion}
                dataProcess={dataProcess}
              />
            )}
            {activeStep < 3 && !show && dataProcess?.statusId !== APPROVED && (
              <Permitted
                tag={constants.permissionTags.LEGITIMATE_INTEREST.MANAGE}
              >
                <Box display="flex" justifyContent="flex-end" gap={2}>
                  <Button variant="contained" type="submit" disabled={loading}>
                    Salvar rascunho
                  </Button>
                </Box>
              </Permitted>
            )}
          </form>
        </FormProvider>
      </Grid>
      <Grid item xl={3} lg={3} md={4} xs={12}>
        <StepperLia
          handleNext={handleNext}
          handleBack={handleBack}
          show={show}
        />
      </Grid>
    </Grid>
  )
}

export default QuestionsDpo
