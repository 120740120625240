import QuestionnairesMain from './QuestionnairesMain'
import QuestionnairesEdit from './QuestionnairesEdit'
import CreateQuestion from './CreateQuestion'
import EditQuestion from './EditQuestion'

export default {
  QuestionnairesMain,
  QuestionnairesEdit,
  CreateQuestion,
  EditQuestion,
}
