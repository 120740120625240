import { MUI } from 'components'

import { ChipStatusType } from './ChipStatus.types'

import legitimateInterest from 'constants/legitimateInterest'

const ChipStatus = ({ statusId }: ChipStatusType) => {
  const statusBackgroundColor = () => {
    return (
      (statusId && legitimateInterest.CHIP_STATUS_BACKGROUND_COLOR[statusId]) ||
      ''
    )
  }

  const statusLabel = () => {
    return (statusId && legitimateInterest.CHIP_STATUS_LABEL[statusId]) || ''
  }

  return (
    <MUI.Chip
      label={statusLabel()}
      variant="filled"
      size="medium"
      $background={statusBackgroundColor()}
      $color="white"
      $fontWeight={500}
    />
  )
}

export default ChipStatus
