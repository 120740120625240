const DEFAULT_BUTTONS = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  '|',
  'ul',
  'ol',
  '|',
  'font',
  'fontsize',
  'paragraph',
  'lineHeight',
  'superscript',
  'subscript',
  '|',
  'file',
  'image',
  'video',
  '\n',
  'speechRecognize',
  'spellcheck',
  '|',
  'cut',
  'copy',
  'paste',
  'selectall',
  'copyformat',
  '|',
  'hr',
  'table',
  'link',
  'symbols',
  '|',
  'indent',
  'outdent',
  'left',
  '|',
  'brush',
  'undo',
  'redo',
  '|',
  'find',
  '|',
  'source',
  '|',
  'fullsize',
  'preview',
  'print',
]

export default {
  DEFAULT_BUTTONS,
}
