import { Box, Paper, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Controller, useFormContext } from 'react-hook-form'

import { ConclusionLiaType } from './conclusionLia.types'

import { QuestionsDpoFormType } from '../../schema'
import { QuestionRadio } from './components'
import constants from 'constants/index'

const ConclusionLia = ({
  questions,
  createdAt,
  show = false,
  dataProcess,
}: ConclusionLiaType) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<QuestionsDpoFormType>()

  const { LEGAL_FRAMEWORKS_LIA } = constants.legitimateInterest

  const LegalFramworksName = dataProcess?.legalFrameworks.map(
    (legalFramework) => legalFramework.name
  )

  const findQuestionByOrder = (order: number) =>
    questions?.find((question) => question.order === order)

  return (
    <Paper component={Box} p={4}>
      <Typography fontWeight={700} color="primary">
        Conclusão
      </Typography>
      {LegalFramworksName?.includes(
        LEGAL_FRAMEWORKS_LIA.LEGITIMATE_INTEREST
      ) && (
        <QuestionRadio
          name="questionDpo37"
          questionText={findQuestionByOrder(37)?.text}
          errorMessage={errors?.questionDpo37?.message}
          control={control}
          disabled={show}
        />
      )}
      {LegalFramworksName?.includes(LEGAL_FRAMEWORKS_LIA.FRAUD_PREVENTION) && (
        <QuestionRadio
          name="questionDpo38"
          questionText={findQuestionByOrder(38)?.text}
          errorMessage={errors?.questionDpo38?.message}
          control={control}
          disabled={show}
        />
      )}
      <Box mt={2}>
        <Typography>{findQuestionByOrder(39)?.text}</Typography>
        <Controller
          control={control}
          name="questionDpo39"
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              multiline
              minRows={3}
              disabled={show}
            />
          )}
        />
      </Box>
      <Box mt={2} display="flex" gap={2}>
        <Box>
          <Typography>{findQuestionByOrder(40)?.text}</Typography>
          <Controller
            render={({ field }) => (
              <DatePicker
                format="dd/MM/yyyy"
                minDate={createdAt && new Date(Date.parse(createdAt))}
                {...field}
                slotProps={{
                  textField: {
                    helperText: errors?.questionDpo40?.message,
                    error: !!errors.questionDpo40,
                  },
                }}
                disabled={show}
              />
            )}
            control={control}
            name="questionDpo40"
          />
        </Box>
        <Box>
          <Typography>{findQuestionByOrder(41)?.text}</Typography>
          <Controller
            control={control}
            name="questionDpo41"
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.questionDpo40}
                helperText={errors?.questionDpo41?.message}
                disabled={show}
              />
            )}
          />
        </Box>
      </Box>
    </Paper>
  )
}

export default ConclusionLia
