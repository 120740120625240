import { Fragment } from 'react'

import { Box, Divider, Paper, Tooltip, Typography, colors } from '@mui/material'

import { TicketInfoTableType } from './tickeInfoTable.types'

import { ButtonGroup } from 'components'

import { formatters } from 'helpers'
import ticketConstants from 'constants/tickets'
import constants from 'constants/index'
import ticketFeedbackConstants from 'constants/ticketFeedbacks'

const TicketInfoTable = ({
  ticket,
  titleButtonGroup,
  setTitleButtonGroup,
  isJuridic,
}: TicketInfoTableType) => {
  const responsibleCompanyInfo =
    ticket?.company?.responsibleUser?.name +
    '\n' +
    ticket?.company?.responsibleUser?.email +
    '\n' +
    formatters.phoneDynamicMask(ticket?.company?.responsibleUser?.phone || '')
  const userInfo = ticket?.user?.name + '\n' + ticket?.user?.email

  const optionsTable = [
    {
      title: 'Categoria',
      value: ticket?.title,
      show: true,
    },
    {
      title: 'Título',
      value: ticket?.subtitle,
      show: true,
    },
    {
      title: 'Responsável pela Organização',
      value: responsibleCompanyInfo,
      show: !!ticket?.company,
    },
    {
      title: 'Solicitante',
      value: userInfo,
      show: true,
    },
    {
      title: 'Tipo da Solicitação',
      value: ticketConstants.TICKET_KIND_TRANSLATION[ticket?.ticketType],
      show: true,
    },
    {
      title: 'Avaliação',
      value:
        ticket?.ticketFeedback?.rating &&
        ticketFeedbackConstants.TICKET_FEEDBACK_RATING_LABELS[
          ticket?.ticketFeedback?.rating
        ],
      show: !!ticket?.ticketFeedback,
    },
  ]

  const formType = () => {
    return isJuridic ? 'juridic-ticket-content-form' : '"ticket-content-form"'
  }

  return (
    <Box>
      <Paper component={Box}>
        <Box px={5} py={3}>
          <Typography variant="subtitle1" fontWeight={500} color="primary">
            Informações
          </Typography>
        </Box>
        {optionsTable.map(
          (option) =>
            option.show && (
              <Box key={option.title}>
                <Divider />
                <Tooltip title={option.value}>
                  <Box
                    p={5}
                    bgcolor={
                      option.title === 'Categoria'
                        ? colors.lightGreen['A100']
                        : 'white'
                    }
                  >
                    {isJuridic && option.title === 'Categoria' ? (
                      <Fragment>
                        <Typography
                          variant="subtitle2"
                          color="primary"
                          fontWeight={500}
                        >
                          Categoria
                        </Typography>
                        <ButtonGroup
                          options={constants.tickets.TICKET_TITLES}
                          title={''}
                          fullWidth
                          minTitleWidth={103}
                          buttonGroup={titleButtonGroup}
                          setButtonGroup={setTitleButtonGroup}
                          disableTouchRipple
                          tooltipPlacement="left"
                          form={formType()}
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Typography
                          variant="subtitle2"
                          color="primary"
                          fontWeight={500}
                        >
                          {option.title}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          whiteSpace="pre-line"
                        >
                          {option.value ? option.value : '-'}
                        </Typography>
                      </Fragment>
                    )}
                  </Box>
                </Tooltip>
              </Box>
            )
        )}
      </Paper>
    </Box>
  )
}

export default TicketInfoTable
