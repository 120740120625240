import axios from 'axios'

import service from 'service'
import api from 'service/api'
import { getBaseURL } from 'service/env'

const dponetAPI = api.create('dponet')
const baseURL = getBaseURL('dponet')

const get = async ({ params = {} }: { params?: object }) => {
  return dponetAPI.get(`/advisors/organization_quiz_templates/`, {
    params,
  })
}

const show = async ({
  quizTemplateId = '',
  params = {},
}: {
  quizTemplateId?: number | string
  params?: object
}) => {
  return dponetAPI.get(
    `/advisors/organization_quiz_templates/${quizTemplateId}`,
    {
      params,
    }
  )
}

const post = async ({ quizTemplate = {} }: { quizTemplate?: object }) => {
  return dponetAPI.post(`/advisors/organization_quiz_templates`, {
    quizTemplate,
  })
}

const update = async ({
  quizTemplateId = '',
  quizTemplate = {},
}: {
  quizTemplateId?: number | string
  quizTemplate?: object
}) => {
  return dponetAPI.put(
    `/advisors/organization_quiz_templates/${quizTemplateId}`,
    {
      quizTemplate,
    }
  )
}

const linkCompanies = async ({
  quizTemplateId = '',
  quizTemplate = {},
}: {
  quizTemplateId?: number | string
  quizTemplate?: object
}) => {
  return dponetAPI.patch(
    `/advisors/organization_quiz_templates/${quizTemplateId}/vinculate_companies`,
    {
      quizTemplate,
    }
  )
}

const uncoupleCompanies = async ({
  quizTemplateId = '',
  quizTemplate = {},
}: {
  quizTemplateId?: number | string
  quizTemplate?: object
}) => {
  return dponetAPI.patch(
    `/advisors/organization_quiz_templates/${quizTemplateId}/unvinculate_company`,
    {
      quizTemplate,
    }
  )
}

const getQuestions = async ({
  quizTemplateId = '',
  params = {},
}: {
  quizTemplateId?: number | string
  params?: object
}) => {
  return dponetAPI.get(
    `/advisors/organization_quiz_templates/${quizTemplateId}/organization_quiz_question_templates`,
    {
      params,
    }
  )
}

const createQuestion = async ({
  quizTemplateId = '',
  quizQuestion = {},
}: {
  quizTemplateId?: number | string
  quizQuestion?: object
}) => {
  return dponetAPI.post(
    `/advisors/organization_quiz_templates/${quizTemplateId}/organization_quiz_question_templates`,
    {
      quizQuestion,
    }
  )
}

const updateQuestion = async ({
  quizTemplateId = '',
  quizQuestionId = '',
  quizQuestion = {},
}: {
  quizTemplateId?: number | string
  quizQuestionId?: number | string
  quizQuestion?: object
}) => {
  return dponetAPI.put(
    `/advisors/organization_quiz_templates/${quizTemplateId}/organization_quiz_question_templates/${quizQuestionId}`,
    {
      quizQuestion,
    }
  )
}

const showQuestion = async ({
  quizTemplateId = '',
  quizQuestionId = '',
  params = {},
}: {
  quizTemplateId?: number | string
  quizQuestionId?: number | string
  params?: object
}) => {
  return dponetAPI.get(
    `/advisors/organization_quiz_templates/${quizTemplateId}/organization_quiz_question_templates/${quizQuestionId}`,
    {
      params,
    }
  )
}

const inactiveQuestion = async ({
  quizTemplateId = '',
  quizQuestionId = '',
  quizQuestion = {},
}: {
  quizTemplateId?: number | string
  quizQuestionId?: number | string
  quizQuestion?: object
}) => {
  return dponetAPI.put(
    `/advisors/organization_quiz_templates/${quizTemplateId}/organization_quiz_question_templates/${quizQuestionId}/inactivate`,
    {
      quizQuestion,
    }
  )
}

const getVinculatedCompanies = async ({
  quizTemplateId = '',
  params = {},
}: {
  quizTemplateId?: number | string
  params?: object
}) => {
  return dponetAPI.get(
    `/advisors/organization_quiz_templates/${quizTemplateId}/list_vinculated_companies`,
    {
      params,
    }
  )
}

const createActionPlan = async ({
  quizTemplateId = '',
  quizQuestionId = '',
  actionPlan = {},
}: {
  quizTemplateId?: number | string
  quizQuestionId?: number | string
  actionPlan?: object
}) => {
  return dponetAPI.post(
    `/advisors/organization_quiz_templates/${quizTemplateId}/organization_quiz_question_templates/${quizQuestionId}/action_plans`,
    {
      actionPlan,
    }
  )
}

const updateActionPlan = async ({
  quizTemplateId = '',
  quizQuestionId = '',
  actionPlanId = '',
  actionPlan = {},
}: {
  quizTemplateId?: number | string
  quizQuestionId?: number | string
  actionPlanId?: number | string
  actionPlan?: object
}) => {
  return dponetAPI.put(
    `/advisors/organization_quiz_templates/${quizTemplateId}/organization_quiz_question_templates/${quizQuestionId}/action_plans/${actionPlanId}`,
    {
      actionPlan,
    }
  )
}

const addDocumentActionPlan = async ({
  quizTemplateId = '',
  quizQuestionId = '',
  actionPlanId = '',
  files = [],
}: {
  quizTemplateId?: number | string
  quizQuestionId?: number | string
  actionPlanId?: number | string
  files: File[]
}) => {
  const documentFormData = new FormData()
  for (const [key, value] of Object.entries(files)) {
    documentFormData.append(`document-${key}`, value)
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/advisors/organization_quiz_templates/${quizTemplateId}/organization_quiz_question_templates/${quizQuestionId}/action_plans/${actionPlanId}/document`,
    data: documentFormData,
    ...config,
  })
}

const deleteDocument = async ({
  quizTemplateId = '',
  quizQuestionId = '',
  actionPlanId = '',
  documentId = '',
}: {
  quizTemplateId?: number | string
  quizQuestionId?: number | string
  actionPlanId?: number | string
  documentId?: number | string
}) => {
  return dponetAPI.delete(
    `/advisors/organization_quiz_templates/${quizTemplateId}/organization_quiz_question_templates/${quizQuestionId}/action_plans/${actionPlanId}/document/${documentId}`
  )
}

export default {
  get,
  show,
  post,
  update,
  linkCompanies,
  uncoupleCompanies,
  getQuestions,
  createQuestion,
  updateQuestion,
  showQuestion,
  inactiveQuestion,
  getVinculatedCompanies,
  createActionPlan,
  updateActionPlan,
  addDocumentActionPlan,
  deleteDocument,
}
