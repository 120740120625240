import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Grid, Paper } from '@mui/material'

import {
  ConditionalLoading,
  DefaultPageStructure,
  LoadingFeedback,
  PageHead,
  PaperHeader,
} from 'components'
import {
  // OrganizationDestroy,
  OrganizationForm,
  OrganizationUploadImage,
  OrganizationUser,
  PermittedOrganization,
  PlatformCustomizationBox,
  TwoFactorAuthentication,
} from './components'

import { useFetch, useOrganization, useSnackbar } from 'hooks'

import service from 'service'
import { formatters } from 'helpers'
import routes from 'constants/routes'
import permissionTagsConstants from 'constants/permissionTags'

const OrganizationUpdate = () => {
  const { organizationId } = useParams()
  const { loadOrganizations, currentOrganization } = useOrganization()
  const { snackbar } = useSnackbar()
  const navigate = useNavigate()

  const [loadingUpdateOrganization, setLoadingUpdateOrganization] =
    useState(false)

  const {
    response: responseOrganization,
    loading: loadingOrganization,
    refresh: refreshOrganization,
  } = useFetch<{ organizationId?: string }>(service.dponet.organization.get, {
    organizationId,
  })

  const organization = responseOrganization?.data?.organization
  const logoPath = organization?.logo

  const updateOrganization = async (organization: object, refresh = true) => {
    try {
      setLoadingUpdateOrganization(true)

      await service.dponet.organization.update({
        organizationId: organizationId,
        ...organization,
      })

      if (refresh) {
        refreshOrganization()
        loadOrganizations()
      }
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error, false),
        variant: 'error',
      })
    } finally {
      setLoadingUpdateOrganization(false)
    }
  }

  const handleReturn = () => navigate(routes.app.organizations.dashboard)

  return (
    <DefaultPageStructure>
      <PageHead name="Grupo Advisor" />
      <LoadingFeedback
        open={loadingOrganization || loadingUpdateOrganization}
      />

      <Paper component={Box} p={2} mb={6}>
        <PaperHeader
          title="Detalhes"
          actionButton={
            <PermittedOrganization
              tag={permissionTagsConstants.ORGANIZATION.MANAGE}
              permissions={organization?.organizationUser?.permissions}
            >
              {/* <OrganizationDestroy
                  setLoading={setLoadingUpdateOrganization}
                  refresh={loadOrganizations}
                  organization={organization}
                /> */}

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                form="organization-updation-form"
              >
                SALVAR
              </Button>
            </PermittedOrganization>
          }
        />

        <Box padding={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={2}>
              <OrganizationUploadImage
                organizationId={organization?.id}
                name={organization?.name}
                src={logoPath}
              />
            </Grid>

            <Grid item xs={12} md={10}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <ConditionalLoading loading={loadingOrganization}>
                  <OrganizationForm
                    organization={organization}
                    onSubmit={updateOrganization}
                    canDisable
                  />
                </ConditionalLoading>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      {!loadingOrganization && (
        <>
          <PermittedOrganization
            tag={permissionTagsConstants.ORGANIZATION.MANAGE}
            permissions={organization?.organizationUser?.permissions}
          >
            <TwoFactorAuthentication
              onSubmit={updateOrganization}
              organization={organization}
            />
          </PermittedOrganization>

          <PermittedOrganization
            someTags={[
              permissionTagsConstants.ORGANIZATION_USER.VIEW,
              permissionTagsConstants.ORGANIZATION_USER.MANAGE,
            ]}
            permissions={organization?.organizationUser?.permissions}
          >
            <OrganizationUser
              organization={organization}
              organizationId={organizationId}
              permissions={organization?.organizationUser?.permissions}
            />
          </PermittedOrganization>
        </>
      )}

      <PermittedOrganization
        tag={permissionTagsConstants.CLIENT_PORTAL_THEME.MANAGE}
        permissions={organization?.organizationUser?.permissions}
      >
        {currentOrganization?.id == organizationId && (
          <PlatformCustomizationBox
            organizationId={organizationId}
            logoPath={logoPath}
          />
        )}
      </PermittedOrganization>
      <Box display="flex" justifyContent="end" mt={4}>
        <Button variant="outlined" onClick={handleReturn}>
          Voltar
        </Button>
      </Box>
    </DefaultPageStructure>
  )
}

export default OrganizationUpdate
