import { isEmpty, isNil } from 'lodash'

import { DefaultAttachmentType } from 'types/file.types'
import { StorageMeasurementUnitType } from 'constants/validations'

import constants from 'constants/index'
import theme from 'theme'

const { BYTE_UNIT_EXPONENT, MIME_TYPE_VALIDATION, UNKNOWN_FILE_MIME_TYPE } =
  constants.validations

const size = (
  unit: StorageMeasurementUnitType,
  sizeLimit: number,
  files?: File | FileList
) => {
  if (isNil(files) || (files instanceof FileList && isEmpty(files))) return true

  const exponentiation = BYTE_UNIT_EXPONENT[unit]
  if (files instanceof FileList)
    return Array.from(files).every(
      (file) => file?.size <= sizeLimit * exponentiation
    )
  else return files?.size <= sizeLimit * exponentiation
}

const type = (permittedTypes: string[], files?: File | FileList) => {
  if (isNil(files) || (files instanceof FileList && isEmpty(files))) return true

  if (files instanceof FileList)
    return Array.from(files).every((file) =>
      permittedTypes.includes(file?.type)
    )
  return permittedTypes.includes(files?.type)
}

const uniqueFile = (files?: FileList) => {
  if (!files || files.length === 0) return true

  const fileNames = Array.from(files)?.map((file) => file.name)
  const hasDuplicates = fileNames.some(
    (name, idx) => fileNames.indexOf(name) !== idx
  )

  return !hasDuplicates
}

const isSameFile = (file1: File, file2: File) => {
  return file1?.name === file2?.name && file1?.size === file2?.size
}

const isFileInList = (file: File, fileList: File[]) => {
  const filesArray = fileList ? Array.from(fileList) : []

  return (
    Array.isArray(filesArray) &&
    filesArray?.some((apiFile) => isSameFile(file, apiFile))
  )
}

const filterExistingFiles = (fileList: FileList, apiFiles: File[]) => {
  const filesArray = fileList ? Array.from(fileList) : []

  return (
    Array.isArray(filesArray) &&
    filesArray?.filter((file) => !isFileInList(file, apiFiles))
  )
}

const arrayToFileList = (files: File[]) => {
  const dataTransfer = new DataTransfer()
  if (isEmpty(files)) return dataTransfer

  files.forEach((file) => dataTransfer.items.add(file))

  return dataTransfer.files
}

const findFileIdByName = (
  filesRemovedArray: File[],
  apiFiles: DefaultAttachmentType[]
) => {
  if (isEmpty(filesRemovedArray) || isEmpty(apiFiles)) return []

  return filesRemovedArray?.flatMap((file) =>
    apiFiles
      .filter(
        (apiFile) =>
          apiFile.name === file.name || apiFile.url.includes(file.name)
      )
      .map((apiFile) => apiFile.id)
  )
}

const chooseInputColor = (
  error: boolean,
  isDragging: boolean,
  disabled: boolean
) => {
  if (disabled) return theme.palette.inactive.light
  else if (error)
    return isDragging ? theme.palette.error.dark : theme.palette.error.main
  return isDragging ? theme.palette.secondary.main : theme.palette.neutral.main
}

const getMimeTypeFromExtension = (filename: string) => {
  const extension = filename
    .substring(filename.lastIndexOf('.') + 1)
    .toLowerCase()

  return MIME_TYPE_VALIDATION[extension] || UNKNOWN_FILE_MIME_TYPE
}

export default {
  size,
  type,
  uniqueFile,
  isSameFile,
  filterExistingFiles,
  isFileInList,
  arrayToFileList,
  findFileIdByName,
  chooseInputColor,
  getMimeTypeFromExtension,
}
