import { Button, Skeleton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { ListPageStructure, NoData, PageHead, Permitted } from 'components'
import { DataProcessTemplateTable } from './components'

import { useFetch } from 'hooks'

import { DataProcessTemplateType } from 'types/dataProcessTemplate.types'

import routes from 'constants/routes'
import service from 'service'
import permissionTags from 'constants/permissionTags'

const DataProcessTemplateMain = () => {
  const navigate = useNavigate()

  const { response, loading } = useFetch(
    service.dponet.dataProcessTemplate.get,
    {},
    []
  )

  const dataProcessTemplates: DataProcessTemplateType[] =
    response?.data?.dataProcessTemplates || []

  const handleRedirectToNewPage = () => {
    navigate(routes.app.dataProcesses.templates.new)
  }

  return (
    <ListPageStructure>
      <PageHead
        name="Processos"
        actionButton={
          <Permitted tag={permissionTags.DATA_PROCESS_TEMPLATE.MANAGE}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleRedirectToNewPage}
            >
              Novo Modelo
            </Button>
          </Permitted>
        }
      />
      {loading ? (
        <Skeleton variant="rounded" height={200} />
      ) : isEmpty(dataProcessTemplates) ? (
        <NoData title="Nenhum processo foi encontrado." />
      ) : (
        <DataProcessTemplateTable dataProcessTemplates={dataProcessTemplates} />
      )}
    </ListPageStructure>
  )
}

export default DataProcessTemplateMain
