import { formatters } from 'helpers'
import convertType from './convertType'
import { isBoolean, isNumber, isNil } from 'lodash'

const formattedValue = function (
  field: string,
  value: string | number | boolean | null
): string | number {
  if (isNil(value)) return '-'

  if (isBoolean(value)) {
    if (value) return 'Sim'

    return 'Não'
  } else {
    const valueAsNumber = isNumber(value) ? value : parseFloat(value)

    switch (field) {
      case 'ticket_type':
        return convertType(field, valueAsNumber)
      case 'visibility_type':
        return convertType(field, valueAsNumber)
      case 'status_updated_at':
        return formatters.date.ptBrFormatDate(value)
      default:
        return value
    }
  }
}

export default formattedValue
