import { Box, Button, Grid, Paper, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'

import { PaperHeader, Permitted } from 'components'
import { PermissionSection } from './components'

import { usePermission } from 'hooks'

import { ProfileFormType } from './profileForm.types'

import schema from './schema'
import routes from 'constants/routes'
import constants from 'constants/index'
import permissionTagsConstants from 'constants/permissionTags'

const ProfileForm = ({ onSubmit, profile }: ProfileFormType) => {
  const { isPermitted } = usePermission()
  const navigate = useNavigate()

  const transformFunctionalites = () => {
    if (!profile) return {}
    if (!profile.profileFunctionalities) return {}

    const functs: { [keyOf: string]: boolean } = {}
    for (const item of profile.profileFunctionalities.filter(
      (funct) => funct.status
    )) {
      functs[item.tag] = item.status
    }

    return functs
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: profile?.name || '',
      description: profile?.description || '',
      permissions: transformFunctionalites(),
    },
    reValidateMode: 'onChange',
  })

  const edittionPermitted = isPermitted(
    permissionTagsConstants.ADVISOR_PROFILE.MANAGE
  )

  const handleReturn = () => navigate(routes.app.profiles.all)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper component={Box} p={2} mb={6}>
        <PaperHeader
          title="Informações gerais"
          actionButton={
            <Permitted tag={permissionTagsConstants.ADVISOR_PROFILE.MANAGE}>
              <Button variant="contained" color="secondary" type="submit">
                SALVAR
              </Button>
            </Permitted>
          }
        />
        <Grid container spacing={4} marginTop={2}>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Nome do Perfil"
                  error={!!errors.name}
                  helperText={<>{errors?.name?.message}</>}
                  disabled={!edittionPermitted}
                />
              )}
              name="name"
              control={control}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Descrição"
                  error={!!errors.description}
                  helperText={<>{errors?.description?.message}</>}
                  disabled={!edittionPermitted}
                />
              )}
              name="description"
              control={control}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper component={Box} p={2} mb={6}>
        <PaperHeader title="Permissões" />
        <Grid container spacing={6} marginY={2}>
          <PermissionSection
            name="Enquadramento Legal"
            items={constants.functionalities.LEGAL_FRAMEWORK_FUNCTIONALITIES}
            control={control}
          />
          <PermissionSection
            name="Empresas"
            items={constants.functionalities.COMPANY_FUNCTIONALITIES}
            control={control}
          />
          <PermissionSection
            name="Questionários"
            items={constants.functionalities.QUESTIONNAIRES_FUNCTIONALITIES}
            control={control}
          />
          <PermissionSection
            name="Classificadores"
            items={constants.functionalities.TAGS_FUNCTIONALITIES}
            control={control}
          />
          <PermissionSection
            name="Atendimento ao Titular"
            items={
              constants.functionalities.SOLICITATION_TICKETS_FUNCTIONALITIES
            }
            control={control}
          />
          <PermissionSection
            name="Configuração do Canal de Atendimento"
            items={
              constants.functionalities
                .PRIVACY_PORTAL_CONFIGURATION_FUNCTIONALITIES
            }
            control={control}
          />
          <PermissionSection
            name="Grupo Advisor"
            items={constants.functionalities.ORGANIZATION_FUNCTIONALITIES}
            control={control}
          />
          <PermissionSection
            name="Perfis de Usuário"
            items={constants.functionalities.ADVISOR_PROFILE_FUNCTIONALITIES}
            control={control}
          />
          <PermissionSection
            name="Incidentes"
            items={constants.functionalities.INCIDENTS_FUNCTIONALITIES}
            control={control}
          />
          <PermissionSection
            name="Solicitações"
            items={constants.functionalities.JURIDIC_TICKETS_FUNCTIONALITIES}
            control={control}
          />
          <PermissionSection
            name="Atividades"
            items={constants.functionalities.TASK_FUNCTIONALITIES}
            control={control}
          />
          <PermissionSection
            name="Legítimo Interesse"
            items={constants.functionalities.LEGITIMATE_INTEREST_FUNCIONALITIES}
            control={control}
          />
          <PermissionSection
            name="Modelos de Processos"
            items={
              constants.functionalities.DATA_PROCESS_TEMPLATE_FUNCTIONALITIES
            }
            control={control}
          />
        </Grid>
      </Paper>
      <Box display="flex" justifyContent="end" mt={4}>
        <Button variant="outlined" onClick={handleReturn}>
          Voltar
        </Button>
      </Box>
    </form>
  )
}

export default ProfileForm
