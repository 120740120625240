import { validates } from 'helpers'
import * as yup from 'yup'

const schema = yup.object({
  content: yup
    .string()
    .required()
    .test('hasContent', 'Preencha esse campo', validates.jodit.hasContent),
})

export type TicketFormFormType = yup.InferType<typeof schema>

export type TicketFormControlType = 'content' | 'attachment'

export default schema
