import { useState } from 'react'

import { FilterInput, Filters } from 'components'

import { OrganizationCompanyType } from 'types/organizationCompany'
import { LegalTicketFilterType } from './LegalTicketFilter.types'

import { useFetch } from 'hooks'

import service from 'service'
import { formatters } from 'helpers'
import constants from 'constants/index'

const LegalTicketFilter = ({ filter }: LegalTicketFilterType) => {
  const [organizationCompanyFilter, setOrganizationCompanyFilter] = useState<
    string | null
  >()

  const { LEGAL_TICKET_TYPES, TICKET_TITLES, TICKET_STATUS_FILTER } =
    constants.tickets

  const validCompanyFilterInput =
    !!organizationCompanyFilter && organizationCompanyFilter?.length >= 3

  const { response: responseCompanies, loading: loadingCompanies } = useFetch(
    service.dponet.organizationCompany.get,
    {
      params: {
        companyName: organizationCompanyFilter,
        perPage: 999,
        minimal: true,
        status: constants.organizationCompanies.ACTIVE_STATUS,
      },
    },
    [organizationCompanyFilter],
    validCompanyFilterInput
  )

  return (
    <Filters filter={filter}>
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'ID da Solicitação', name: 'id' }}
      />
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        loading={loadingCompanies && validCompanyFilterInput}
        autocompleteOnChange={(data) => {
          setOrganizationCompanyFilter(data)
        }}
        {...{
          label: 'Nome da Organização',
          name: 'companyId',
          noOptionsText: formatters.autoCompleteNoText(
            organizationCompanyFilter || ''
          ),
          options: responseCompanies?.data?.organizationCompanies?.map(
            (organizationCompany: OrganizationCompanyType) => ({
              id: organizationCompany?.company?.id,
              name: organizationCompany?.company?.name,
            })
          ),
        }}
      />
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Documento da Organização', name: 'companyDocument' }}
      />
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Solicitante', name: 'requester' }}
      />
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        {...{
          label: 'Tipo da Solicitação',
          name: 'ticketType',
          options: LEGAL_TICKET_TYPES,
        }}
      />
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        {...{
          label: 'Categoria',
          name: 'title',
          options: TICKET_TITLES,
        }}
      />
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Responsável', name: 'responsibleName' }}
      />
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        {...{
          label: 'Status',
          name: 'statusId',
          options: TICKET_STATUS_FILTER,
        }}
      />
    </Filters>
  )
}

export default LegalTicketFilter
