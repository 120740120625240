import Main from './LegitimateInterestMain'
import ReviewClient from './LegitimateInterestReviewClient'
import ReviewDpo from './LegitimateInterestReviewDpo'
import Show from './LegitimateInterestShow'

const LegitimateInterest = {
  Main,
  ReviewClient,
  ReviewDpo,
  Show,
}

export default LegitimateInterest
