import { validates } from 'helpers'
import * as yup from 'yup'

const schema = yup.object({
  content: yup
    .string()
    .required()
    .test('hasContent', 'Preencha esse campo', validates.jodit.hasContent),
  attachment: yup.mixed(),
  // .test('fileType', 'Tipo de arquivo não suportado.', validateFileType)
  // .test('fileSize', 'Tamanho máximo excedido (10MB).', validateFileSize),
})

export type TicketContentFormType = yup.InferType<typeof schema>

export type TicketContentControlType = 'content' | 'attachment'

export default schema
