import { inRange, size } from 'lodash'
import { SupplierType } from 'types/organizationCompany'

const countSupplierByCompliance = (
  supplierStats: SupplierType[],
  complianceMin: number,
  complianceMax: number
) => {
  const suppliers = supplierStats?.filter((supplier) => {
    if (!supplier.compliance) supplier.compliance = 0

    return inRange(supplier.compliance, complianceMin, complianceMax)
  })
  return size(suppliers)
}

export default { countSupplierByCompliance }
