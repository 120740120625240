import { Box, TextField, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useSnackbar } from 'hooks'

import { formatters } from 'helpers'

import { QuestionChangeStatusFormType } from './questionChangeStatusForm.types'

import schema, { QuestionChangeStatusFormSchemaType } from './schema'
import constants from 'constants/index'
import service from 'service'

const QuestionChangeStatusForm = ({
  formName,
  isInactive = false,
  onClose,
  questionId,
  questionnaireId,
  refresh,
  setIsLoading,
}: QuestionChangeStatusFormType) => {
  const { snackbar } = useSnackbar()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<QuestionChangeStatusFormSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: {
      reason: '',
      status: isInactive
        ? constants.questionnaire.INACTIVE_QUESTION_STATUS
        : constants.questionnaire.ACTIVE_QUESTION_STATUS,
    },
  })

  const onSubmit = async (data: QuestionChangeStatusFormSchemaType) => {
    try {
      setIsLoading(true)
      if (isInactive) {
        await service.dponet.questionnaires.inactiveQuestion({
          quizTemplateId: questionnaireId,
          quizQuestionId: questionId,
          quizQuestion: { reason: data?.reason },
        })
      } else {
        throw new Error('Não é possível ativar questões!')
      }
      snackbar.open({
        message: `Questão inativada com sucesso!`,
        variant: 'success',
      })
      reset()
      onClose()
      refresh()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      id={formName}
      display="flex"
      flexDirection="column"
      gap={4}
    >
      <Typography gutterBottom>
        Deseja realmente {isInactive ? 'inativar' : 'ativar'} essa questão?
      </Typography>
      {isInactive && (
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors?.reason}
              helperText={errors?.reason?.message}
              label="Motivo da inativação"
              type="text"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
            />
          )}
          control={control}
          name="reason"
        />
      )}
      {isInactive && (
        <Typography fontWeight={500} color="error" textAlign="center">
          Lembre-se de que esta ação é irreversível, e não será possível
          ativá-lo novamente!
        </Typography>
      )}
    </Box>
  )
}

export default QuestionChangeStatusForm
