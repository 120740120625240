import * as yup from 'yup'

const schema = yup.object({
  questionDpo1: yup.string().required().max(1000),
  questionDpo2: yup.string().required().max(1000),
  questionDpo3: yup.string().required().max(1000),
  questionDpo4: yup.string().required().max(1000),
  questionDpo5: yup.string().required().max(1000),
  questionDpo6: yup.string().required().max(1000),
  questionDpo7: yup.string().required().max(1000),
  questionDpo8: yup.string().required().max(1000),
  questionDpo9: yup.string().required().max(1000),
  questionDpo10: yup.string().required().max(1000),
  questionDpo11: yup.string().required().max(1000),
  questionDpo12: yup.string().required().max(1000),
  questionDpo13: yup.string().required().max(1000),
  questionDpo14: yup.string().required().max(1000),
  questionDpo15: yup.string().required().max(1000),
  questionDpo16: yup.string().required().max(1000),
  questionDpo17: yup.string().required().max(1000),
  questionDpo18: yup.string().required().max(1000),
  questionDpo19: yup.string().required().max(1000),
  questionDpo20: yup.string().required().max(1000),
  questionDpo21: yup.string().required().max(1000),
  questionDpo22: yup.string().required().max(1000),
  questionDpo23: yup.string().required().max(1000),
  questionDpo24: yup.string().required().max(1000),
  questionDpo25: yup.string().required().max(1000),
  questionDpo26: yup.string().required().max(1000),
  questionDpo27: yup.string().required().max(1000),
  questionDpo28: yup.string().required().max(1000),
  questionDpo29: yup.string().required().max(1000),
  questionDpo30: yup.string().required().max(1000),
  questionDpo31: yup.string().required().max(1000),
  questionDpo32: yup.string().required().max(1000),
  questionDpo33: yup.string().required().max(1000),
  questionDpo34: yup.string().required().max(1000),
  questionDpo35: yup.string().required().max(1000),
  questionDpo36: yup.string().required().max(1000),
  questionDpo37: yup.string().required(),
  questionDpo38: yup.string().required(),
  questionDpo39: yup.string().max(1000),
  questionDpo40: yup.date().required(),
  questionDpo41: yup.string().required().max(1000),
})

export type RequiredQuestionsDpoFormType = yup.InferType<typeof schema>

export default schema
