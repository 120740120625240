import * as yup from 'yup'

const schemaAnswersAnalysis = yup.object({
  question1IsPresent: yup.boolean(),
  question2IsPresent: yup.boolean(),
  question3IsPresent: yup.boolean(),
  question4IsPresent: yup.boolean(),
  question5IsPresent: yup.boolean(),
  question6IsPresent: yup.boolean(),
  question7IsPresent: yup.boolean(),
  question8IsPresent: yup.boolean(),
  question9IsPresent: yup.boolean(),
  question10IsPresent: yup.boolean(),
  question11IsPresent: yup.boolean(),
  order1: yup.number().when('question1IsPresent', {
    is: true,
    then: () => yup.number().required(),
  }),
  order2: yup.number().when('question2IsPresent', {
    is: true,
    then: () => yup.number().required(),
  }),
  order3: yup.number().when('question3IsPresent', {
    is: true,
    then: () => yup.number().required(),
  }),
  order4: yup.number().when('question4IsPresent', {
    is: true,
    then: () => yup.number().required(),
  }),
  order5: yup.number().when('question5IsPresent', {
    is: true,
    then: () => yup.number().required(),
  }),
  order6: yup.number().when('question6IsPresent', {
    is: true,
    then: () => yup.number().required(),
  }),
  order7: yup.number().when('question7IsPresent', {
    is: true,
    then: () => yup.number().required(),
  }),
  order8: yup.number().when('question8IsPresent', {
    is: true,
    then: () => yup.number().required(),
  }),
  order9: yup.number().when('question9IsPresent', {
    is: true,
    then: () => yup.number().required(),
  }),
  order10: yup.number().when('question10IsPresent', {
    is: true,
    then: () => yup.number().required(),
  }),
  order11: yup.number().when('question11IsPresent', {
    is: true,
    then: () => yup.number().required(),
  }),
  answerDpoQuestion1WasApproved: yup.boolean().when('question1IsPresent', {
    is: true,
    then: () => yup.boolean().required('Selecione Aprovado ou Reprovado'),
  }),
  answerDpoQuestion2WasApproved: yup.boolean().when('question2IsPresent', {
    is: true,
    then: () => yup.boolean().required('Selecione Aprovado ou Reprovado'),
  }),
  answerDpoQuestion3WasApproved: yup.boolean().when('question3IsPresent', {
    is: true,
    then: () => yup.boolean().required('Selecione Aprovado ou Reprovado'),
  }),
  answerDpoQuestion4WasApproved: yup.boolean().when('question4IsPresent', {
    is: true,
    then: () => yup.boolean().required('Selecione Aprovado ou Reprovado'),
  }),
  answerDpoQuestion5WasApproved: yup.boolean().when('question5IsPresent', {
    is: true,
    then: () => yup.boolean().required('Selecione Aprovado ou Reprovado'),
  }),
  answerDpoQuestion6WasApproved: yup.boolean().when('question6IsPresent', {
    is: true,
    then: () => yup.boolean().required('Selecione Aprovado ou Reprovado'),
  }),
  answerDpoQuestion7WasApproved: yup.boolean().when('question7IsPresent', {
    is: true,
    then: () => yup.boolean().required('Selecione Aprovado ou Reprovado'),
  }),
  answerDpoQuestion8WasApproved: yup.boolean().when('question8IsPresent', {
    is: true,
    then: () => yup.boolean().required('Selecione Aprovado ou Reprovado'),
  }),
  answerDpoQuestion9WasApproved: yup.boolean().when('question9IsPresent', {
    is: true,
    then: () => yup.boolean().required('Selecione Aprovado ou Reprovado'),
  }),
  answerDpoQuestion10WasApproved: yup.boolean().when('question10IsPresent', {
    is: true,
    then: () => yup.boolean().required('Selecione Aprovado ou Reprovado'),
  }),
  answerDpoQuestion11WasApproved: yup.boolean().when('question11IsPresent', {
    is: true,
    then: () => yup.boolean().required('Selecione Aprovado ou Reprovado'),
  }),
  answerDpoQuestion1: yup.string().when('answerDpoQuestion1WasApproved', {
    is: false,
    then: () => yup.string().required().max(1000),
  }),
  answerDpoQuestion2: yup.string().when('answerDpoQuestion2WasApproved', {
    is: false,
    then: () => yup.string().required().max(1000),
  }),
  answerDpoQuestion3: yup.string().when('answerDpoQuestion3WasApproved', {
    is: false,
    then: () => yup.string().required().max(1000),
  }),
  answerDpoQuestion4: yup.string().when('answerDpoQuestion4WasApproved', {
    is: false,
    then: () => yup.string().required().max(1000),
  }),
  answerDpoQuestion5: yup.string().when('answerDpoQuestion5WasApproved', {
    is: false,
    then: () => yup.string().required().max(1000),
  }),
  answerDpoQuestion6: yup.string().when('answerDpoQuestion6WasApproved', {
    is: false,
    then: () => yup.string().required().max(1000),
  }),
  answerDpoQuestion7: yup.string().when('answerDpoQuestion7WasApproved', {
    is: false,
    then: () => yup.string().required().max(1000),
  }),
  answerDpoQuestion8: yup.string().when('answerDpoQuestion8WasApproved', {
    is: false,
    then: () => yup.string().required().max(1000),
  }),
  answerDpoQuestion9: yup.string().when('answerDpoQuestion9WasApproved', {
    is: false,
    then: () => yup.string().required().max(1000),
  }),
  answerDpoQuestion10: yup.string().when('answerDpoQuestion10WasApproved', {
    is: false,
    then: () => yup.string().required().max(1000),
  }),
  answerDpoQuestion11: yup.string().when('answerDpoQuestion11WasApproved', {
    is: false,
    then: () => yup.string().required().max(1000),
  }),
})

export type AnswersLiaReviewFormType = yup.InferType<
  typeof schemaAnswersAnalysis
>

export type AnswersLiaReviewKeyType = keyof AnswersLiaReviewFormType

export default schemaAnswersAnalysis
