import { ListPageStructure, LoadingFeedback, PageHead } from 'components'
import { useParams } from 'react-router-dom'

import { GeneralInformations, QuestionsDpo } from './components'

import { useFetch } from 'hooks'

import service from 'service'
import constants from 'constants/index'

const LegitimateInterestReviewDpo = () => {
  const { dataProcessId } = useParams()

  const { response, loading } = useFetch(
    service.dponet.dataProcesses.showWithLia,
    {
      dataProcessId,
    }
  )

  const { DPO } = constants.legitimateInterest.ROLES

  const dataProcess = response?.data?.dataProcess
  const answersClientApproved = dataProcess?.liaAnswers

  const {
    response: responseLiaQuestionsWithAnswers,
    loading: loadingLiaQuestionsWithAnswers,
    refresh,
  } = useFetch(service.dponet.liaQuestion.getWithAnswers, {
    params: { dataProcessId },
  })

  const liaQuestionsWithAnswers =
    responseLiaQuestionsWithAnswers?.data?.liaQuestions

  const {
    response: responseOnlyLiaQuestions,
    loading: loadingOnlyLiaQuestions,
  } = useFetch(service.dponet.liaQuestion.get, {
    params: {
      dataProcessId,
      role: DPO,
      order: 'lia_questions.order asc',
    },
  })

  const onlyQuestions = responseOnlyLiaQuestions?.data?.liaQuestions

  return (
    <ListPageStructure>
      <LoadingFeedback
        open={
          loading || loadingLiaQuestionsWithAnswers || loadingOnlyLiaQuestions
        }
      />
      <PageHead name="Legítimo Interesse (LIA)" />
      <GeneralInformations dataProcess={dataProcess} />
      {!loading &&
        !loadingLiaQuestionsWithAnswers &&
        !loadingOnlyLiaQuestions && (
          <QuestionsDpo
            onlyQuestions={onlyQuestions}
            liaQuestionsWithAnswers={liaQuestionsWithAnswers}
            dataProcess={dataProcess}
            companyCity={dataProcess?.companyCity}
            createdAt={dataProcess?.createdAt}
            refresh={refresh}
            answersClientApproved={answersClientApproved}
          />
        )}
    </ListPageStructure>
  )
}

export default LegitimateInterestReviewDpo
