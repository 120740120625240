import {
  Box,
  StepConnector,
  StepIconProps,
  Button,
  Typography,
} from '@mui/material'

import {
  StyledStep,
  StyledStepLabel,
  StyledStepper,
  HexagonBox,
} from 'components'

import { useLegitimateInterest } from 'hooks'

import { StepperLiaType, StepCompleteType } from './stepperLia.types'

const StepperLia = ({
  handleNext,
  handleBack,
  show = false,
}: StepperLiaType) => {
  const steps = ['Finalidade', 'Necessidade', 'Balanceamento e Salvaguarda']

  const {
    stepFinalityCompleted,
    stepFinalityIncomplete,
    stepNecessityCompleted,
    stepNecessityIncomplete,
    stepBalancingSafeguardingCompleted,
    stepBalancingSafeguardingIncomplete,
    activeStep,
  } = useLegitimateInterest()

  const stepComplete = (active: string) => {
    const stepComplete: StepCompleteType = {
      0: stepFinalityCompleted,
      1: stepNecessityCompleted,
      2: stepBalancingSafeguardingCompleted,
    }

    return stepComplete[active]
  }

  const stepIncomplete = (active: string) => {
    const stepComplete: StepCompleteType = {
      0: stepFinalityIncomplete,
      1: stepNecessityIncomplete,
      2: stepBalancingSafeguardingIncomplete,
    }

    return stepComplete[active]
  }

  const Icon = (props: StepIconProps) => {
    let icon = Number(props.icon)
    icon -= 1

    const findTypeHexagon = () => {
      if (
        props.active ||
        (activeStep === icon && !props.completed) ||
        (icon < activeStep && !props.completed && props.active)
      ) {
        return 'blueHexagon'
      }
      if (props.completed) {
        return 'greenHexagon'
      }

      return 'none'
    }

    return (
      <HexagonBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        mr={2}
        width={40}
        height={40}
        typehexagon={findTypeHexagon()}
      />
    )
  }

  const findLabelProps = (
    index: number,
    complete: boolean,
    incomplete: boolean
  ) => {
    if (!complete && !incomplete && activeStep !== index) {
      return (
        <Typography variant="caption" color="primary">
          Responder
        </Typography>
      )
    }
    if ((activeStep === index && !complete) || incomplete) {
      return (
        <Typography variant="caption" color="primary">
          Respondendo
        </Typography>
      )
    }

    return (
      <Typography variant="caption" color="primary">
        Respondido
      </Typography>
    )
  }

  return (
    <Box>
      <StyledStepper
        alternativeLabel
        activeStep={activeStep}
        connector={<StepConnector sx={{ display: 'none' }} />}
        orientation="vertical"
        nonLinear
      >
        {steps.map((label, index) => (
          <StyledStep key={label}>
            <StyledStepLabel
              StepIconComponent={Icon}
              optional={findLabelProps(
                index,
                stepComplete(String(index)),
                stepIncomplete(String(index))
              )}
              StepIconProps={{
                completed: stepComplete(String(index)),
                active: stepIncomplete(String(index)),
                id: String(index),
              }}
            >
              <Typography color="primary" variant="body2" fontWeight={600}>
                {label}
              </Typography>
            </StyledStepLabel>
          </StyledStep>
        ))}
      </StyledStepper>
      <Box display="flex" gap={2} justifyContent="flex-end" mt={2}>
        {activeStep > 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Voltar
          </Button>
        )}
        {!(activeStep === 3 && show) && (
          <Button variant="contained" onClick={handleNext}>
            {activeStep === 3 ? 'Finalizar' : 'Avançar'}
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default StepperLia
