import { useEffect, useState } from 'react'
import { Button, Hidden, Tab, TablePagination, Tabs } from '@mui/material'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import { isEmpty } from 'lodash'

import {
  BasicDialog,
  ListPageStructure,
  LoadingFeedback,
  NoData,
  PageHead,
  PagePaper,
  Permitted,
} from 'components'
import { QuestionnairesCreateForm, QuestionnairesTable } from './components'

import { useFetch, useFilter, usePagination, usePermission } from 'hooks'

import { QuestionnaireType } from 'types/questionnaire'

import service from 'service'
import constants from 'constants/index'

const QuestionnairesMain = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [openCreateQuestionnaireModal, setOpenCreateQuestionnaireModal] =
    useState(false)

  const filter = useFilter()
  const { isPermitted } = usePermission()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const createQuestionnairesFormName = 'questionnaires-create-form'

  const { response, loading, refresh } = useFetch(
    service.dponet.questionnaires.get,
    {
      params: {
        ...filter.filters,
        page,
        perPage,
      },
    },
    [filter.filters, page, perPage]
  )

  const questionnaires: QuestionnaireType[] =
    response?.data?.quizTemplates ?? []

  const isPermittedManage = isPermitted(
    constants.permissionTags.QUESTIONNAIRES.MANAGE
  )

  const handleOpenCreateQuestionnaireModal = () => {
    setOpenCreateQuestionnaireModal(true)
  }

  const handleCloseCreateQuestionnaireModal = () => {
    setOpenCreateQuestionnaireModal(false)
  }

  useEffect(() => {
    loading ? setIsLoading(true) : setIsLoading(false)
  }, [loading])

  return (
    <ListPageStructure>
      <LoadingFeedback open={isLoading} />
      <PageHead
        name="Questionários"
        actionButton={
          <Permitted tag={constants.permissionTags.QUESTIONNAIRES.MANAGE}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<PlusCircleIcon size={20} />}
              onClick={handleOpenCreateQuestionnaireModal}
              disabled={!isPermittedManage}
            >
              Criar Questionário
            </Button>
          </Permitted>
        }
      />
      {loading || isEmpty(questionnaires) ? (
        <NoData title="Nenhum questionário foi encontrado." />
      ) : (
        <PagePaper>
          <Tabs value="all" variant="scrollable">
            <Tab label="Todos" value="all" />
          </Tabs>
          <QuestionnairesTable questionnaires={questionnaires} />
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            onPageChange={(_, page) => handleChangePage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </PagePaper>
      )}
      <BasicDialog
        title="Criar questionário"
        form={createQuestionnairesFormName}
        open={openCreateQuestionnaireModal}
        onClose={handleCloseCreateQuestionnaireModal}
      >
        <QuestionnairesCreateForm
          formName={createQuestionnairesFormName}
          onClose={handleCloseCreateQuestionnaireModal}
          refresh={refresh}
          setIsLoading={setIsLoading}
        />
      </BasicDialog>
    </ListPageStructure>
  )
}

export default QuestionnairesMain
