import { ChipStatusQuestionnairesType } from 'constants/questionnaire'
import { ChipColorType } from 'types/materialUI.types'

import constants from 'constants/index'

const labelTranslation = (status: ChipStatusQuestionnairesType) => {
  return constants.questionnaire.QUESTION_CHIP_STATUS_TRANSLATION[status] || '-'
}

const statusColor = (status: ChipStatusQuestionnairesType) => {
  return (
    (constants.questionnaire.QUESTION_CHIP_STATUS_COLOR[
      status
    ] as ChipColorType) || 'default'
  )
}

export default {
  labelTranslation,
  statusColor,
}
